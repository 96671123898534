import getRequest from '../../../../../getRequest';

/**
 * Get a list of the product types.
 *
 * @param {number} siteId,
 * @returns objects
 */
export default function getSiteAttributes(siteId) {
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/get-all-site-level-attributes/${siteId}`;
  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'GetProductsCount')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
