import deleteRequest from '../../../deleteRequest';

async function deleteAllIndex() {
  const endpoint = '/oc-product/v1/index/delete-all';
  const errorMessageHeader = 'Delete All';

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, null, errorMessageHeader)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}

export default deleteAllIndex;
