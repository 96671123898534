import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import InitialSidebarOptions from '../sidebar/menu/InitialSidebarOptions';
import SidebarAccordions from '../sidebar/menu/SidebarAccordions';

function MainMenu() {
  const siteId = useSelector(
    (state) => state?.user?.loginResponse?.selectedSite?.SiteId,
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredSidebarAccordions, setFilteredSidebarAccordions] = useState(SidebarAccordions);
  const user = useSelector((state) => state.user);
  const selectedSite = useSelector(
    (state) => state.user?.loginResponse?.selectedSite,
  );
  const [selectedMenu, setSelectedMenu] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubItemClick = (event, link) => {
    event.preventDefault();
    navigate(link);
    setAnchorEl(null);
  };

  const handleMenuClick = (event, option) => {
    if (selectedSite.SiteCode === 'OC' && option.id === 'dataManagement') {
      navigate('/data-management/attributes-definition');
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedMenu(option.id);
      if (
        option.id === 'dashboard'
        || option.id === 'productInfo'
        || option.id === 'bulkOperations'
        || option.id === 'dropship'
        || option.id === 'styles'
        || option.id === 'marginThreshold'
      ) {
        navigate(option.link);
        setAnchorEl(null);
      } else if (option.id === 'reports') {
        window.open(option.link, '_blank');
      }
    }
  };

  useEffect(() => {
    if (
      user?.userProfile?.user_permission !== 'Admin' && user?.userProfile?.user_permission !== 'GroupAdmin'
    ) {
      const updatedAccordions = SidebarAccordions.map((section) => {
        if (section.settings) {
          if (selectedSite?.SiteCode === 'OC' && section.dataManagement) {
            return {
              ...section,
              settings: section.settings.filter((item) => item.name !== 'Users' && item.name !== 'Indexing' && item.name !== 'User Management'),
              dataManagement: section.dataManagement.filter((item) => item.name !== 'Attributes Definition' && item.name !== 'Product Type'
                && item.name !== 'Category' && item.name !== 'Brand Grouping' && item.name !== 'Size Mapping' && item.name !== 'Colour Matching'),
            };
          }
          return {
            ...section,
            settings: section.settings.filter((item) => item.name !== 'Users' && item.name !== 'Indexing' && item.name !== 'User Management'),
          };
        }
        return section;
      });
      setFilteredSidebarAccordions(updatedAccordions);
    } else {
      const updatedAccordions = SidebarAccordions.map((x) => {
        if (x.dataManagement) {
          if (selectedSite?.SiteCode === 'OC') {
            return {
              ...x,
              dataManagement: x.dataManagement.filter((item) => item.name !== 'Attributes Definition' && item.name !== 'Product Type'
                && item.name !== 'Category' && item.name !== 'Brand Grouping' && item.name !== 'Size Mapping' && item.name !== 'Colour Matching'),
            };
          }
        }

        return x;
      });
      setFilteredSidebarAccordions(updatedAccordions);
    }
  }, [siteId, user]);

  return (
    <>
      <style>
        {`
          .menu-item-hover {
            position: relative;
            background-color: transparent!important
          }

          .menu-item-hover::before,
          .menu-item-hover::after {
            content: '';
            position: absolute;
            height: 2px;
            width: 0;
            background-color: #fff;
            transition: width 0.4s ease, left 0.4s ease, right 0.4s ease;
          }

          .menu-item-hover::before {
            top: 78%;
            left: 0;
          }

          .menu-item-hover::after {
            bottom: 15%;
            right: 0;
          }

          /* On hover, animate the lines inwards */
          .menu-item-hover:hover::before {
            width: 100%;
            left: 0;
          }

          .menu-item-hover:hover::after {
            width: 100%;
            right: 0;
          }

          /* On hover out, retract the lines in the same direction */
          .menu-item-hover::before {
            width: 0;
            left: 0;
          }

          .menu-item-hover::after {
            width: 0;
            right: 0;
          }
          .menu-item-hover:hover p {
            transform: translate3d(0, -2px, 0);
          }
          .menu-item-hover p {
            transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1)
          }
        `}
      </style>
      <Box
        sx={{
          flexGrow: 1,
          display: {
            xs: 'none',
            md: 'none',
            lg: 'flex',
            xl: 'flex',
          },
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        {InitialSidebarOptions.filter((option) => {
          if (
            selectedSite?.SiteCode === 'OC'
            && user?.userProfile?.user_groupName !== 'ImageChecker'
          ) {
            return [
              'bulkOperations',
              'dataManagement',
              'dashboard',
              'reports',
              'users',
              'styles',
              'release-notes',
            ].includes(option.id);
          }
          if (
            selectedSite?.SiteCode === 'JQ'
            && user?.userProfile?.user_groupName !== 'ImageChecker'
          ) {
            return (
              ['dashboard', 'marginThreshold'].includes(
                option.id,
              ) || option.id === 'logout'
            );
          }
          if (user?.userProfile?.user_groupName === 'ImageChecker') {
            return ['dashboard'].includes(option.id) || option.id === 'logout';
          }
          if (option.id === 'marginThreshold') {
            return false;
          }
          // Display all options for other sitecodes
          return option.id !== 'settings';
        }).map((option) => (
          <MenuItem
            selected={option.id === selectedMenu || pathname === option.link}
            className="font-gothammedium text-[12px] text-white menu-item-hover p-0 m-5"
            key={option.id}
            onClick={(event) => handleMenuClick(event, option)}
          >
            <Typography sx={{ textAlign: 'center' }}>{option.name}</Typography>
          </MenuItem>
        ))}
        <Box sx={{ position: 'absolute', right: '25px', top: '12px' }}>
          {InitialSidebarOptions.filter(
            (option) => option.id === 'settings',
          ).map((option) => (
            <MenuItem
              selected={option.id === selectedMenu}
              className="font-gothammedium text-[12px] text-white"
              key={option.id}
              onClick={(event) => handleMenuClick(event, option)}
              sx={{
                backgroundColor: 'transparent', // Transparent background
                '&:hover': {
                  backgroundColor: 'transparent', // No hover background
                },
                '&.Mui-selected': { backgroundColor: 'transparent' },
              }}
            >
              <Typography>
                <SettingsIcon fontSize="large" />
              </Typography>
            </MenuItem>
          ))}
        </Box>
        {selectedMenu && filteredSidebarAccordions[0][selectedMenu] && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            TransitionComponent={Fade}
          >
            {filteredSidebarAccordions[0][selectedMenu]?.map((obj) => (
              <MenuItem key={obj.name}>
                <Typography onClick={(e) => handleSubItemClick(e, obj.link)} className="font-bold">
                  {obj.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
      </Box>
    </>
  );
}

export default MainMenu;
