import deleteRequest from '../../../../../deleteRequest';

/**
 * Bulk delete a multi attribute from multiple products.
 *
 * @param {{
 *  siteId: number,
 *  sourceId: number,
 *  productAttributeDefId: number,
 *  languageId: number,
 * }}
 * @param {number} siteId Site Id
 * @param {number} sourceId Source Id - Example, 1 for Shogun
 * @param {number} productAttributeDefId Attribute Id
 * @param {number} languageId Language Id
*/
export default async function deleteAttributeValuesFromAllProducts({
  siteId,
  sourceId,
  attributeDefId,
  languageId,
  totalProductsCount,
}) {
  const endpoint = '/oc-product-bulkapi/v1/bulk-process/delete-attributes-values-assign-to-all-products';

  const body = {
    siteId,
    sourceId,
    attributeDefId,
    languageId,
    totalProductsCount,
  };

  const successMessage = 'Started : Delete Attribute values from all products';
  const errorMessageHeader = `Failed to DELETE Values of - DefId: ${attributeDefId}`;

  return new Promise((resolve, reject) => {
    deleteRequest(endpoint, body, errorMessageHeader, successMessage)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
