import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Popper, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList as VirtualizedList } from 'react-window';

import JobModal from './components/JobModal';
import ProcessingTabRow from './components/tab_rows/ProcessingTabRow';
import SuccessTabRow from './components/tab_rows/SuccessTabRow';
import TabPanel from './components/TabPanel';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: `${theme.palette.secondary[500]} !important`,
    height: '4px !important',
  },
  selected: {
    color: '#fff !important',
    opacity: '1 !important',
  },
  tabRoot: {
    color: '#fff !important',
    flex: 1,
    fontSize: '12px !important',
  },
}));

// const STATUS_DEF = {
//   Wait: 0,
//   New: 1,
//   Active: 2,
//   Processing: 3,
//   PartiallyProcessed: 4,
//   Processed: 5,
//   Failed: 10,
//   Removed: 20,
//   Disabled: 30,
// };

export default function ProgressSidebar({
  page, inProgress, updateInProgressData, endpoint,
}) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);

  const tabPanel = React.useRef(null);

  const styles = useStyles();

  const [value, setValue] = React.useState(2);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [
    selectedId,
    setSelectedId,
  ] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const [inProgressData, setInProgressData] = React.useState([]);
  const [partialFailedSuccessData, setpartialFailedSuccessData] = React.useState([]);

  const [tabPanelHeight, setTabPanelHeight] = React.useState(0);

  const [selectedTransaction, setSelectedTransaction] = React.useState({});
  const [isLogsOpen, setIsLogsOpen] = useState(true);
  const [username, setUsername] = useState(''.toLowerCase());
  const [openMoreFilters, seOpenMoreFilters] = useState(false);
  const open = Boolean(openMoreFilters);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [resetData, setResetData] = useState(false);
  const [selectedBulkOperationType, setSelectedBulkOperationType] = React.useState(0);
  const [selectedStatusType, setSelectedStatusType] = React.useState(0);
  const statusTypes = [
    {
      label: 'PartiallyProcessed',
      value: 4,
    },
    {
      label: 'Processed',
      value: 5,
    },
    {
      label: 'Failed',
      value: 10,
    },
  ];
  const options = [
    {
      label: 'Approve',
      value: 18,
    },
    {
      label: 'Attributes',
      value: 15,
    },
    {
      label: 'Campaign (P.Id)',
      value: 5,
    },
    {
      label: 'Campaign (Plus)',
      value: 6,
    },
    {
      label: 'Categories',
      value: 11,
    },
    {
      label: 'Collection (P.Id)',
      value: 7,
    },
    {
      label: 'Collection (Plus)',
      value: 8,
    },
    {
      label: 'Enable Stock',
      value: 22,
    },
    {
      label: 'Multi-Select Attributes (P.Id)',
      value: 3,
    },
    {
      label: 'Multi-Select Attributes (Plus)',
      value: 4,
    },
    {
      label: 'Primary Category (P.Id)',
      value: 20,
    },
    {
      label: 'Primary Category (Plus)',
      value: 19,
    },
    {
      label: 'Product Name (P.Id)',
      value: 17,
    },
    {
      label: 'Product Name (Plus)',
      value: 16,
    },
    {
      label: 'Product Type (P.Id)',
      value: 10,
    },
    {
      label: 'Product Type (Plus)',
      value: 9,
    },
    {
      label: 'Single-Select Attributes (P.Id)',
      value: 1,
    },
    {
      label: 'Single-Select Attributes  (Plus)',
      value: 2,
    },
    {
      label: 'VAT (P.Id)',
      value: 25,
    },
    {
      label: 'VAT (Plus)',
      value: 24,
    },
    {
      label: 'Release Dates',
      value: 21,
    },
    {
      label: 'New In Dates (Table Bulk)',
      value: 30,
    },
    {
      label: 'Pre-Release Products (Plus)',
      value: 31,
    },
    {
      label: 'Pre-Release Products (File)',
      value: 32,
    },
    {
      label: 'Delete attribute values',
      value: 35,
    },
  ];
  const handleFilterSelectedPageChange = (event) => {
    setSelectedBulkOperationType(event.target.value);
  };
  const handleFilterSelectedStatusChange = (event) => {
    setSelectedStatusType(event.target.value);
  };
  const handleFilterFromDateChange = (selectedDate) => {
    setFromDate(selectedDate);
  };
  const handleFilterToDateChange = (selectedDate) => {
    const endOfDay = selectedDate ? new Date(selectedDate.setHours(23, 59, 59, 999)) : null;
    setToDate(endOfDay);
  };

  const handleGetReports = () => {
    endpoint(siteId, fromDate, toDate, username, selectedBulkOperationType, selectedStatusType, [2, 3])
      // "Active": 2, "Processing": 3,
      .then((res) => {
        updateInProgressData(res.data, page);
        setInProgressData(res.data);
      })
      .catch((err) => console.error(err));
    endpoint(siteId, fromDate, toDate, username, selectedBulkOperationType, selectedStatusType, [4, 5, 10])
      // "PartiallyProcessed": 4, "Processed": 5, "Failed": 10,
      .then((res) => {
        setpartialFailedSuccessData(res.data);
      })
      .catch((err) => console.error(err));
  };

  const handleFilterReset = () => {
    setUsername('');
    setFromDate(null);
    setToDate(null);
    setSelectedBulkOperationType(0);
    setSelectedStatusType(0);
    setResetData(true);
  };

  React.useEffect(() => {
    if (resetData) {
      handleGetReports();
    }
  }, [resetData]);

  const handleOpenMoreFilter = () => {
    seOpenMoreFilters(true);
  };

  const handleCloseMoreFilter = () => {
    seOpenMoreFilters(false);
  };

  const handleClickAway = () => {
    handleCloseMoreFilter();
  };
  const handleCloseLogs = () => {
    setIsLogsOpen(false);
  };

  const handleOpenLogs = () => {
    setIsLogsOpen(true);
  };

  React.useEffect(() => {
    setInProgressData(inProgress);
  }, [inProgress]);

  React.useEffect(() => {
    setpartialFailedSuccessData(partialFailedSuccessData);
  }, [partialFailedSuccessData]);

  React.useEffect(() => {
    if (tabPanel.current) {
      const { height } = tabPanel.current.getBoundingClientRect();
      setTabPanelHeight((height - 65));
    }
  }, [tabPanel.current]);

  React.useEffect(() => {
    handleGetReports();
    const interval = setInterval(() => {
      if (!fromDate && !toDate && !selectedBulkOperationType && !selectedStatusType && username === '') {
        handleGetReports();
      }
    }, 10000); // 10 seconds
    return () => clearInterval(interval);
  }, [siteId, fromDate, toDate, selectedBulkOperationType, selectedStatusType, username]);

  React.useEffect(() => {
    handleFilterReset();
  }, [page]);

  React.useEffect(() => {
    if (username.length > 0) {
      setUsername('');
    }
    if (fromDate) {
      setFromDate(null);
    }
    if (toDate) {
      setToDate(null);
    }
    if (selectedBulkOperationType) {
      setSelectedBulkOperationType(0);
    }
    if (selectedStatusType) {
      setSelectedStatusType(0);
    }
    if (setIsLogsOpen === false) {
      if (username.length > 0) {
        setUsername('');
      }
    }
  }, [siteId, isLogsOpen]);

  return (
    <section className="h-[calc(100vh-120px)] sticky top-0">
      {openModal && <JobModal id={selectedId} open={openModal} setIsOpen={setOpenModal} parentData={selectedTransaction} />}
      {!isLogsOpen ? (
        <div className="h-[125px] w-[22px] z-10 fixed right-[14px] top-[43%]">
          <a
            href={handleOpenLogs}
            onClick={handleOpenLogs}
            className="h-full items-center text-1xl flex break-all text-center rounded text-white bg-[#217ABA] p-1.5 font-black cursor-pointer"
          >
            LOGS
          </a>
        </div>
      ) : (
        <div className="h-full min-w-[400px]">
          <div className="h-[25px] w-[25px] bg-[#737373] flex justify-center items-center rounded-full z-10 fixed right-[15px] top-[50%]">
            <ArrowForwardIosIcon
              className="text-white cursor-pointer"
              onClick={handleCloseLogs}
            />
          </div>
          <Box sx={{ width: '100%' }} className="h-full border rounded bg-neutral-100" ref={tabPanel}>
            <Box sx={{
              borderBottom: 1,
              borderColor: 'divider',
              position: 'sticky',
              top: 0,
              zIndex: 20,
            }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                classes={{ indicator: styles.indicator, root: styles.selected }}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab
                  label="Processing"
                  {...a11yProps(0)}
                  classes={{
                    root: styles.tabRoot,
                    selected: styles.selected,
                  }}
                />
                <Tab
                  label="Complete"
                  {...a11yProps(1)}
                  classes={{
                    root: styles.tabRoot,
                    selected: styles.selected,
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {inProgressData.length > 0 ? (
                <VirtualizedList
                  height={tabPanelHeight}
                  itemCount={inProgressData.length}
                  itemSize={130}
                  itemData={inProgressData}
                  width={400}
                >
                  {ProcessingTabRow}
                </VirtualizedList>
              ) : (
                <div className="flex items-center justify-center h-16 w-[400px]">
                  <p>No Operations Processing</p>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1} className="testsssss">
              <div className="p-2 mb-5 bg-white border rounded-lg shadow-md">
                <div className="flex flex-col items-center sm:flex-row w-[384px]">
                  <TextField
                    label="Name"
                    variant="outlined"
                    value={username}
                    size="small"
                    onChange={(event) => setUsername(event.target.value)}
                    className="w-[250px]"
                  />
                  <Button
                    onClick={handleOpenMoreFilter}
                    variant="text"
                    className="text-[10px] w-[70px] text-primary-600 mt-[16px] sm:mt-0 sm:ml-[5px] min-w-[42px] mr-5"
                    anchorEl={document.getElementById('more-filters-button')}
                  >
                    <Tooltip
                      title={(
                        <p className="text-sm">More Filters</p>
                      )}
                    >
                      <FilterListIcon />
                    </Tooltip>
                  </Button>
                  {openMoreFilters && (
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Popper
                        open={open}
                        onClose={handleCloseMoreFilter}
                        placement="right"
                        className="absolute left-auto pr-8 bg-white border rounded-lg shadow-md w-1/10 right-20 top-44"
                        id="more-filters-button"
                      >
                        <Typography className="text-primary-600" sx={{ p: 2 }}>More Filters</Typography>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <FormControl style={{ width: '220px' }} className="pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]" size="small" id="status">
                            <InputLabel id="status">Status</InputLabel>
                            <Select
                              labelId="status"
                              id="status"
                              value={selectedStatusType}
                              label="Status"
                              variant="outlined"
                              MenuProps={{ disablePortal: true }}
                              onChange={handleFilterSelectedStatusChange}
                            >
                              {statusTypes.map((obj) => (
                                <MenuItem value={obj.value}>
                                  {obj.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl style={{ width: '220px' }} className="pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]" size="small" id="uploadtype">
                            <InputLabel id="uploadtype">UploadType</InputLabel>
                            <Select
                              labelId="uploadtype"
                              id="uploadtype"
                              value={selectedBulkOperationType}
                              label="UploadType"
                              variant="outlined"
                              MenuProps={{ disablePortal: true }}
                              onChange={handleFilterSelectedPageChange}
                            >
                              {options.map((obj) => (
                                <MenuItem value={obj.value}>
                                  {obj.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        {/* <Grid sx={{ width: 230, ml: 20, mb: 2 }}>
                      </Grid> */}
                        <DatePicker
                          label="From"
                          inputFormat="dd-MM-yyyy"
                          value={fromDate}
                          onChange={handleFilterFromDateChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                            />
                          )}
                          showDaysOutsideCurrentMonth
                          maxDate={toDate ? new Date(toDate) : new Date()}
                          className="pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]"
                        />
                        <DatePicker
                          label="To"
                          inputFormat="dd-MM-yyyy"
                          value={toDate}
                          onChange={handleFilterToDateChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                            />
                          )}
                          showDaysOutsideCurrentMonth
                          minDate={fromDate && new Date(fromDate)}
                          maxDate={new Date()}
                          className="pb-8 w-[220px] mt-[16px] sm:mt-0 sm:ml-[16px]"
                        />
                        <div className="pb-8 w-max">
                          <Button
                            onClick={handleCloseMoreFilter}
                            variant="contained"
                            className="mt-[16px] sm:mt-0 sm:ml-[16px]"
                            disabled={!fromDate && !toDate && !selectedBulkOperationType && !selectedStatusType}
                          >
                            Apply
                          </Button>
                          <Button
                            onClick={handleFilterReset}
                            variant="text"
                            className="text-primary-600 mt-[16px] sm:mt-0 sm:ml-[16px]"
                            disabled={!fromDate && !toDate && !selectedBulkOperationType && !selectedStatusType}
                            sx={{
                              '&.Mui-disabled': {
                                WebkitTextFillColor: 'rgba(0, 0, 0, 0.26)',
                              },
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </Popper>
                    </ClickAwayListener>
                  )}
                  <Button
                    onClick={handleGetReports}
                    variant="contained"
                    className="mt-[16px] sm:mt-0 sm:ml-[16px] text-sm pl-[22px] pr-[22px]"
                    disabled={username === '' && !fromDate && !toDate && !selectedBulkOperationType && !selectedStatusType}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={handleFilterReset}
                    variant="text"
                    className="text-primary-600 mt-[16px] sm:mt-0 sm:ml-[5px] text-sm"
                  >
                    Reset
                  </Button>
                </div>
              </div>
              {partialFailedSuccessData.length > 0 ? (
                <VirtualizedList
                  height={tabPanelHeight}
                  itemCount={partialFailedSuccessData.length}
                  itemSize={140}
                  itemData={partialFailedSuccessData}
                >
                  {({ data, index, style }) => (
                    <SuccessTabRow
                      data={data}
                      index={index}
                      style={style}
                      setSelected={(obj) => {
                        setSelectedId(obj.Id);
                        setSelectedTransaction(obj);
                      }}
                      setOpenModal={setOpenModal}
                    />
                  )}
                </VirtualizedList>
              ) : (
                <div className="flex items-center justify-center h-16 w-[400px]">
                  <p>No Successful Operations</p>
                </div>
              )}
            </TabPanel>
          </Box>
        </div>
      )}
    </section>
  );
}
