/* eslint-disable react/no-array-index-key */
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import InitialSidebarOptions from '../sidebar/menu/InitialSidebarOptions';
import SidebarAccordions from '../sidebar/menu/SidebarAccordions';

function BurgerMenu({
  backgroundColor,
  iconBGColor,
}) {
  const [anchorElBurger, setAnchorElBurger] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const user = useSelector((state) => state.user);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [filteredSidebarAccordions, setFilteredSidebarAccordions] = useState(SidebarAccordions);
  const siteId = useSelector((state) => state?.user?.loginResponse?.selectedSite?.SiteId);
  const selectedSite = useSelector((state) => state.user?.loginResponse?.selectedSite);

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorElNav(null);
    setAnchorElBurger(null);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenBurgerMenu = (event) => {
    setAnchorElBurger(event.currentTarget);
  };

  const handleBurgerSubItemClick = (event, link) => {
    event.preventDefault();
    navigate(link);
    setAnchorElBurger(null);
    setAnchorElNav(null);
  };
  const handleBurgerMenuClick = (event, option) => {
    if (selectedSite.SiteCode === 'OC' && option.id === 'dataManagement') {
      navigate('/data-management/attributes-definition');
      setAnchorElBurger(null);
      setAnchorElNav(null);
    } else {
      handleOpenBurgerMenu(event);
      setAnchorElBurger(event.currentTarget);
      setSelectedMenu(option.id); // Set the clicked menu's ID
      if (option.id === 'dashboard' || option.id === 'productInfo'
        || option.id === 'bulkOperations' || option.id === 'dropship'
        || option.id === 'styles' || option.id === 'marginThreshold') {
        navigate(option.link);
        setAnchorElBurger(null);
        setAnchorElNav(null);
      } else if (option.id === 'reports') {
        setAnchorElBurger(null);
        window.open(option.link, '_blank');
      }
    }
  };

  useEffect(() => {
    if (
      user?.userProfile?.user_permission !== 'Admin' && user?.userProfile?.user_permission !== 'GroupAdmin'
    ) {
      const updatedAccordions = SidebarAccordions.map((section) => {
        if (section.settings) {
          if (selectedSite?.SiteCode === 'OC' && section.dataManagement) {
            return {
              ...section,
              settings: section.settings.filter((item) => item.name !== 'Users' && item.name !== 'Indexing' && item.name !== 'User Management'),
              dataManagement: section.dataManagement.filter((item) => item.name !== 'Attributes Definition' && item.name !== 'Product Type'
                && item.name !== 'Category' && item.name !== 'Brand Grouping' && item.name !== 'Size Mapping' && item.name !== 'Colour Matching'),
            };
          }
          return {
            ...section,
            settings: section.settings.filter((item) => item.name !== 'Users' && item.name !== 'Indexing' && item.name !== 'User Management'),
          };
        }
        return section;
      });
      setFilteredSidebarAccordions(updatedAccordions);
    } else {
      const updatedAccordions = SidebarAccordions.map((x) => {
        if (x.dataManagement) {
          if (selectedSite?.SiteCode === 'OC') {
            return {
              ...x,
              dataManagement: x.dataManagement.filter((item) => item.name !== 'Attributes Definition' && item.name !== 'Product Type'
                && item.name !== 'Category' && item.name !== 'Brand Grouping' && item.name !== 'Size Mapping' && item.name !== 'Colour Matching'),
            };
          }
        }

        return x;
      });
      setFilteredSidebarAccordions(updatedAccordions);
    }
  }, [siteId, user]);
  return (
    <Box sx={{
      flexGrow: 1,
      display: {
        xs: 'flex',
        md: 'flex',
        lg: 'none',
        xl: 'none',
        justifyContent: 'flex-end',
      },
    }}
    >
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon color={backgroundColor} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        TransitionComponent={Fade}
        open={Boolean(anchorElNav)}
        onClose={handleClose}
        sx={{
          display: {
            xs: 'block',
            md: 'block',
            lg: 'none',
            xl: 'none',
          },
        }}
      >
        {InitialSidebarOptions.filter((option) => {
          if (selectedSite?.SiteCode === 'OC' && user?.userProfile?.user_groupName !== 'ImageChecker') {
            return ['bulk-operations', 'dataManagement', 'dashboard', 'reports', 'users', 'styles', 'release-notes'].includes(option.id);
          }
          if (selectedSite?.SiteCode === 'JQ' && user?.userProfile?.user_groupName !== 'ImageChecker') {
            return ['dashboard', 'settings', 'marginThreshold'].includes(option.id) || option.id === 'logout';
          }
          if (user?.userProfile?.user_groupName === 'ImageChecker') {
            return ['dashboard'].includes(option.id) || option.id === 'logout';
          }
          if (option.id === 'marginThreshold') {
            return false;
          }
          return true;
        })
          .map((option, index) => (
            <MenuItem key={index} onClick={(event) => handleBurgerMenuClick(event, option)}>
              <ListItemIcon
                sx={{ color: iconBGColor }}
                className="justify-center transition-all"
              >
                {option.icon}
              </ListItemIcon>
              <Typography sx={{ textAlign: 'center' }}>{option.name}</Typography>
            </MenuItem>
          ))}
      </Menu>
      {selectedMenu && filteredSidebarAccordions[0][selectedMenu] && (
        <Menu
          id={selectedMenu.id}
          key={selectedMenu.id}
          anchorOrigin={{
            vertical: 'right',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorElBurger}
          open={Boolean(anchorElBurger)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          TransitionComponent={Fade}
        >
          {filteredSidebarAccordions[0][selectedMenu]?.map((obj, index) => (
            <MenuItem key={index} onClick={(e) => handleBurgerSubItemClick(e, obj.link)}>
              <Typography>{obj.name}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
}

export default BurgerMenu;
