import AddIcon from '@mui/icons-material/Add';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import {
  DataGrid, gridClasses, GridOverlay, GridToolbar,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import getAllUsers from '../../api/private/users_management/gets/getAllUsers';
import getUserApiAccessTypes from '../../api/private/users_management/gets/getUserApiAccessTypes';
import getUserPermissions from '../../api/private/users_management/gets/getUserPermissions';
import getUserRights from '../../api/private/users_management/gets/getUserRights';
import getUserStatuses from '../../api/private/users_management/gets/getUserStatuses';
import getUserTypes from '../../api/private/users_management/gets/getUserTypes';
import postDisableUser from '../../api/private/users_management/posts/postDisableUser';
import CloneUserModal from './modals/CloneUser';
import ConfirmationModal from './modals/ConfirmationModal ';
import CreateNewUserModal from './modals/CreateNewUser';
import ViewEditUserModal from './modals/ViewEditUser';

styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        0.2 + theme.palette.action.selectedOpacity,
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          0.2 + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
        ),
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            0.2 + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

function CustomizedNoRowsOverlay(onAddNewUser) {
  return (
    <GridOverlay className="h-1/3">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex" p={2}>
        <h1 className="text-2xl text-stone-500 font-gothambook">No results have been returned</h1>
        <h1 className="mt-1 mb-5 text-sm font-bold text-stone-700">Change search parameter or</h1>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          sx={{
            borderRadius: '20px',
            width: 150,
            height: 40,
            backgroundColor: '#384451',
          }}
          onClick={onAddNewUser}
        >
          Add User
        </Button>
      </Box>
    </GridOverlay>
  );
}

function UserList({
  search,
  userTypes,
  userRights,
  apiAccessTypes,
  setUserTypes,
  setUserRights,
  setApiAccessTypes,
  userPermission,
  setUserPermission,
  usersCount,
  onAddNewUser,
  setUsersCount,
}) {
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(20);
  const [userStatus, setUserStatus] = React.useState({});
  const [loadingTable, setLoadingTable] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [valueChanged, setValueChanged] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState({});
  const [buttonAnchor, setButtonAnchor] = React.useState(null);
  const [lookupsLoaded, setLookupsLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [requestType, seRequestType] = useState('');
  const userDetails = useSelector((state) => state.user.userProfile);

  const getTableInformation = async (searchKey, ut, aat, ur, us, up) => {
    setLoadingTable(true);

    try {
      const userList = await getAllUsers(searchKey);
      const userTypeEntries = Object.entries(ut);
      const apiATEntries = Object.entries(aat);
      const userRightsEntries = Object.entries(ur);
      const userStatusEntries = Object.entries(us);
      const userPermissionEntries = Object.entries(up);

      return userList.data.map((obj) => {
        const foundUserType = userTypeEntries.find(([, value]) => value === obj.UserType);
        const foundUserRight = userRightsEntries.find(([, value]) => value === obj.UserRight);
        const foundApiAccessType = apiATEntries.find(([, value]) => value === obj.ApiAccessType);
        const foundStatus = userStatusEntries.find(([, value]) => value === obj.Status);
        const foundUserPermission = userPermissionEntries.find(([, value]) => value === obj.UserPermission);

        return {
          ...obj,
          UserType: foundUserType ? foundUserType[0] : obj.UserType,
          UserRight: foundUserRight ? foundUserRight[0] : obj.UserRight,
          ApiAccessType: foundApiAccessType ? foundApiAccessType[0] : obj.ApiAccessType,
          Status: foundStatus ? foundStatus[0] : obj.Status,
          UserPermission: foundUserPermission ? foundUserPermission[0] : obj.UserPermission,
        };
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    setLoadingTable(false);

    return true;
  };

  async function getAllMappings() {
    const ut = new Promise((resolve, reject) => {
      getUserTypes()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const aat = new Promise((resolve, reject) => {
      getUserApiAccessTypes()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const ur = new Promise((resolve, reject) => {
      getUserRights()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const us = new Promise((resolve, reject) => {
      getUserStatuses()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
    const up = new Promise((resolve, reject) => {
      getUserPermissions()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });

    try {
      return await Promise.all([ut, aat, ur, us, up]); // Returns [10, 20, 30]
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  const handleMenuOpen = (event, id) => {
    event.stopPropagation();
    setSelectedUserId(id);
    setButtonAnchor(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation();
    setSelectedUserId(null);
    setButtonAnchor(null);
  };
  const Disableuser = async () => {
    try {
      const responseData = await postDisableUser(
        selectedUser.id,
      );
      if (responseData.data) {
        toast.success(responseData.data);
        const userList = await getTableInformation(search, userTypes, apiAccessTypes, userRights, userStatus, userPermission);
        setRows(userList);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingTable(false);
    }
  };
  const checkPendingUserBlockedOrNot = (date) => {
    const InsertDate = new Date(date);
    const now = new Date();
    const diffiNminutes = now - InsertDate;
    const diffInDays = diffiNminutes / (1000 * 60 * 60 * 24);
    return diffInDays;
  };

  const handleDisable = () => {
    setShowConfirmation(true);
  };

  const handleEnabled = () => {
    seRequestType('Enabled');
    setOpenModal(true);
  };

  const columns = [
    {
      field: 'AccountName',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'UserName',
      headerName: 'Email Address',
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'UserPermission',
      headerName: 'Permission',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'GroupName',
      headerName: 'Group',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'LastVerificationDate',
      headerName: 'Last Login',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => {
        const date = new Date(params.value);
        return params.row.Status === 'Pending' ? 'Never logged in'
          : date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })
            .replace(',', '');
      },
    },
    {
      field: 'Status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      renderCell: (group) => {
        const diffInDays = checkPendingUserBlockedOrNot(group.row.InsertDate);
        let chipProps = { label: 'Disabled', color: 'default', variant: 'outlined' };
        switch (group.row.Status) {
          case 'New':
            chipProps = { label: 'New', color: 'warning', variant: 'outlined' };
            break;
          case 'Active':
            chipProps = { label: 'Active', color: 'success', variant: 'outlined' };
            break;
          case 'Pending':
            if (diffInDays >= 7) {
              chipProps = { label: 'Blocked', color: 'error', variant: 'outlined' };
            } else {
              chipProps = { label: 'Pending', color: 'error', variant: 'outlined' };
            }
            break;
          default:
            break;
        }
        return <Chip {...chipProps} />;
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      renderCell: (user) => (
        <>
          <Button
            size="small"
            onClick={(event) => {
              handleMenuOpen(event, user.row.Id);
            }}
            onClose={(event) => handleMenuClose(event)}
          >
            <MoreVertSharpIcon />
          </Button>
          <Menu
            id={`menu-${user.Id}`}
            anchorEl={buttonAnchor}
            open={selectedUserId === user.row.Id}
            onClose={(event) => handleMenuClose(event)}
          >
            <div>
              {(
                user.row.Status === 'Disabled' ? (
                  <MenuItem onClick={(event) => {
                    setSelectedUser(user.row);
                    handleEnabled();
                    seRequestType('Enabled');
                    handleMenuClose(event);
                  }}
                  >
                    Enable
                  </MenuItem>

                ) : (
                  <>
                    <MenuItem
                      onClick={(event) => {
                        setSelectedUser(user.row);
                        setOpenModal(true);
                        handleMenuClose(event);
                        seRequestType('View');
                      }}
                    >
                      View
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        setSelectedUser(user.row);
                        setOpen(true);
                        handleMenuClose(event);
                      }}
                    >
                      Clone
                    </MenuItem>
                    {(userDetails.user_permission === 'Admin'
                      || (userDetails.user_permission === 'GroupAdmin' && user.row.GroupId === userDetails.user_groupId))
                      && (
                        <MenuItem onClick={(event) => {
                          handleDisable(user);
                          setSelectedUser(user);
                          handleMenuClose(event);
                        }}
                        >
                          Disable
                        </MenuItem>
                      )}
                  </>
                )
              )}
            </div>
          </Menu>
        </>
      ),
    },
  ];

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoadingTable(true);
      try {
        const [ut, aat, ur, us, up] = await getAllMappings();

        setUserTypes(ut);
        setApiAccessTypes(aat);
        setUserRights(ur);
        setUserStatus(us);
        setUserPermission(up);
        setLookupsLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoadingTable(false);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (lookupsLoaded) {
      const getTableDetails = async () => {
        setLoadingTable(true);
        try {
          const userList = await getTableInformation(search, userTypes, apiAccessTypes, userRights, userStatus, userPermission);
          setRows(userList);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoadingTable(false);
        }
      };

      getTableDetails();
    }
  }, [lookupsLoaded, search, usersCount, valueChanged]);

  const handleProcessRowUpdateError = React.useCallback((error) => {
    toast.error(error);
  }, []);

  const handleConfirm = async (confirmed) => {
    try {
      if (confirmed) {
        setShowConfirmation(false);
        Disableuser();
      } else {
        setShowConfirmation(false);
      }

      // toast.success('Attribute successfully created for new sites');
    } catch (error) {
      toast.error('An error occurred during finalization');
    }
  };
  return (
    <>
      {showConfirmation && (
        <ConfirmationModal
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          onConfirm={() => handleConfirm(true)} // Pass true for confirmation
          onCancel={() => handleConfirm(false)} // Pass false for cancellation
          message="Disabling user will restrict them from logging into Prism"
          user={selectedUser}
        />
      )}
      <div className="width: '100%'; margin-right: -20px;">
        <Box
          component="div"
          sx={{
            height: 'calc(100vh - 220px)',
            width: 'calc(100vw - 126px)',
            mt: -1,
            ml: 3.5,
            mr: -30,
          }}
        >
          {openModal
            && (
              <ViewEditUserModal
                open={openModal}
                handleClose={() => {
                  setOpenModal(false);
                }}
                userTypesMapping={userTypes}
                apiAccessTypesMapping={apiAccessTypes}
                userPermissionMapping={userPermission}
                selectedUser={selectedUser}
                setValueChanged={setValueChanged}
                userStatus={userStatus}
                type={requestType}
              />
            )}
          {open && (
            <CreateNewUserModal
              open={open}
              handleClose={() => {
                setOpen(false);
              }}
              userTypesMapping={userTypes}
              apiAccessTypesMapping={apiAccessTypes}
              userPermissionMapping={userPermission}
            />
          )}
          {open && (
            <CloneUserModal
              open={open}
              handleClose={() => {
                setOpen(false);
              }}
              userTypesMapping={userTypes}
              apiAccessTypesMapping={apiAccessTypes}
              userPermissionMapping={userPermission}
              cloneUserMapping={selectedUser}
              usersCount={usersCount}
              setUsersCount={setUsersCount}
            />
          )}
          <DataGrid
            rows={rows}
            columns={columns}
            experimentalFeatures={{ newEditingApi: true }}
            getRowId={(r) => r.Id}
            slots={{
              toolbar: GridToolbar,
              noRowsOverlay: (() => CustomizedNoRowsOverlay(onAddNewUser)),
            }}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            disableSelectionOnClick
            className="bg-white"
            getRowHeight={() => 'auto'}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            pagination
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[20, 30, 40]}
            loading={loadingTable}
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          />
        </Box>
      </div>
    </>

  );
}

export default UserList;
