import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import getLanguages from '../../../api/oc_product/product/languages/gets/getLanguages';
import deleteAttribute from '../../../api/oc_product/productDataManagement/attributes/delete/deleteAttribute';
import getAttributeDataTypes from '../../../api/oc_product/productDataManagement/attributes/get/getAttributeDataTypes';
import getFaciaProductCount from '../../../api/oc_product/productDataManagement/attributes/get/getFaciaProductCount';
import getSimilarLanguageSites from '../../../api/oc_product/productDataManagement/attributes/get/getSimilarLanguageSites';
import postAttributes from '../../../api/oc_product/productDataManagement/attributes/post/postAttributes';
import Modal from '../../../components/Modal';
import { AttributeDefinitionsContext } from './AttributeDefinitionsContext';
import Filter from './components/filter/Filter';
import AttributeLogs from './components/logs/AttributeLogs';
import CreateModal from './components/modals/CreateModal';
import EditModal from './components/modals/EditModal';

function CustomPagination(
  selectedPage,
  onChangeSelectedPage,
  pageSize,
  onChangePageSize,
  pageAmount,
) {
  return (
    <div className="flex justify-end gap-4">
      <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center mt-5 mb-0 sm:mt-0">
        <Typography variant="body2" className="mr-5">Rows per page:</Typography>
        <Select
          value={pageSize}
          onChange={onChangePageSize}
          autoWidth
          size="small"
          variant="standard"
        >
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={400}>400</MenuItem>
          <MenuItem value={600}>600</MenuItem>
          <MenuItem value={800}>800</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={pageAmount}
        page={selectedPage}
        onChange={onChangeSelectedPage}
        size="small"
      />
    </div>
  );
}

function CustomToolbar(setOpenCreate) {
  return (
    <div className="flex flex-row justify-end mt-2 mr-2">
      <Button
        variant="contained"
        color="success"
        onClick={() => setOpenCreate(true)}
      >
        Create New Attribute
      </Button>
    </div>
  );
}

function AttributeDefinitions() {
  // const navigate = useNavigate();
  const [openCreate, setOpenCreate] = useState(false);

  const languageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const selectedSite = useSelector((state) => state.user?.loginResponse?.selectedSite);
  const [rows, setRows] = useState([]);
  const [faciaProductRows, setFaciaProductRows] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [pageAmount, setPageAmount] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const [deleteRow, setDeleteRow] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openFaciaModal, setOpenFaciaModal] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [faciaProductCountLoading, setFaciaProductCountLoading] = React.useState(false);

  const [siteOptions, setSiteOptions] = React.useState([]);
  const [filterOpen, setFilterOpen] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [languageOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [defKeyType, setDefKeyType] = useState('name');
  const [id, setId] = useState('');
  const [idError, setIdError] = useState(false);
  const [dataType, setDataType] = useState(null);
  const [name, setName] = useState('');
  const [attributeDataTypes, setAttributeDataTypes] = useState([]);
  const [readOnly, setReadOnly] = useState(null);
  const [hidden, setHidden] = useState(null);
  const [mandatory, setMandatory] = useState(null);
  const [includeInSearchKeywords, setIncludeInSearchKeywords] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const [globalVal, setGlobalVal] = useState(null);
  const [includeProductCount, setIncludeProductCount] = useState(false);
  const [includeCountCol, setIncludeCountCol] = useState(false);
  const [reRenderLogs, setReRenderLogs] = useState(false);

  const defLang = languageOptions.find((obj) => obj.LanguageId === languageId);

  const fetchData = async () => {
    if (includeProductCount) {
      setIncludeCountCol(true);
    } else {
      setIncludeCountCol(false);
    }
    try {
      const attributeDefId = (id === '' || !id) ? null : Number(id);
      const res = await postAttributes(
        selectedPage,
        rowsPerPage,
        siteId,
        selectedSite.LanguageId,
        name,
        attributeDefId,
        readOnly,
        hidden,
        mandatory,
        includeInSearchKeywords,
        defaultValue,
        selectedSite.SiteCode === 'OC' ? true : globalVal,
        dataType?.AttributeDataTypeId,
        includeProductCount,
      );
      if (res) {
        setTotalRows(res.data?.Pagination.TotalCount);
        setPageAmount(res.data?.Pagination.PageCount);
        setRows(res.data?.Data);
      }
    } catch (error) {
      console.error('Error fetching attribute definitions:', error);
    }
  };

  const fetchAttributeDataTypes = async () => {
    try {
      const res = await getAttributeDataTypes();
      setAttributeDataTypes(res.data);
    } catch (error) {
      console.error('Error fetching attribute data types:', error);
    }
  };

  const handleGetSites = async () => {
    try {
      const res = await getSimilarLanguageSites(languageId);
      if (res) {
        setSiteOptions(res.data);
      }
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
  };

  const onChangePageSize = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalRows / newItemsPerPage);
    const newPage = Math.min(selectedPage, newPageCount);
    setRowsPerPage(newItemsPerPage);
    setSelectedPage(newPage);
  };

  const onChangeSelectedPage = (event, newPage) => {
    setSelectedPage(newPage);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await deleteAttribute(deleteRow?.ProductAttributeDefId);
      toast.success(`${deleteRow?.ProductAttributeDefKey} successfully deleted`);
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete attribute');
    } finally {
      setOpenFaciaModal(false);
      setDeleteLoading(false);
      setReRenderLogs(true);
      fetchData();
    }
  };

  const handleProductCountClick = async (params) => {
    try {
      setFaciaProductCountLoading(true);
      const result = await getFaciaProductCount(params.ProductAttributeDefId, params.IsSingleLine);
      setFaciaProductRows(result?.data);
    } catch (error) {
      console.error(error);
      toast.error('Failed to fetch facia product count info');
    } finally {
      setFaciaProductCountLoading(false);
    }
  };

  const resetAllFilters = () => {
    setId('');
    setDataType(null);
    setName('');
    setReadOnly(null);
    setHidden(null);
    setMandatory(null);
    setIncludeInSearchKeywords(null);
    setDefaultValue(null);
    setGlobalVal(null);
    setIncludeProductCount(false);
  };

  const openEditModal = (data) => {
    setSelectedRow(data);
    setOpenEdit(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  React.useEffect(() => {
    getLanguages(siteId).then((res) => setLanguagesOptions(res.data));
    fetchData();
    fetchAttributeDataTypes();
    resetAllFilters();
  }, [siteId]);

  React.useEffect(() => {
    if (languageId && languageOptions.length > 0) {
      setSelectedLanguage(languageOptions.find((obj) => obj.LanguageId === languageId));
    }
    if (languageId) {
      handleGetSites();
    }
  }, [languageId, languageOptions]);

  React.useEffect(() => {
    fetchData();
  }, [selectedPage, rowsPerPage]);
  const columnsFaciaProduct = [
    {
      field: 'Facia',
      headerName: 'Facia',
      flex: 1,
      minWidth: 80,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ProductCount',
      headerName: 'ProductCount',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
    }];
  const columns = [
    {
      field: 'ProductAttributeDefId',
      headerName: 'ID',
      flex: 1,
      minWidth: 80,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ProductAttributeDefKey',
      headerName: 'Definition Key',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
    },
    defLang?.LanguageName !== 'English' && {
      field: 'LocalName',
      headerName: `${defLang ? `${defLang.LanguageName} Name` : 'Local Name'}`,
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'EnglishName',
      headerName: `${defLang?.LanguageName === 'English' ? 'Name' : 'English Name'}`,
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'DataType',
      headerName: 'Data Type',
      flex: 1,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'Created',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
    {
      field: 'Updated',
      headerName: 'Updated Date',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
    includeCountCol && {
      field: 'TotalUsageCount',
      headerName: 'Product Count',
      flex: 1,
      minWidth: 40,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={(event) => {
            event.stopPropagation();
            setOpenFaciaModal(true);
            handleProductCountClick(params.row);
          }}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: 'IsGlobal',
      headerName: 'Global',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsReadOnly',
      headerName: 'Read Only',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsHidden',
      headerName: 'Hidden',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IsMandatory',
      headerName: 'Mandatory',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'IncludeInSearchKeywords',
      headerName: 'Include In Search Keywords',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        params.value ? (
          <CheckCircleIcon className="text-success-600" />
        ) : (
          <CancelIcon className="text-error-600" />
        )
      ),
    },
    {
      field: 'DefaultValue',
      headerName: 'Default Value',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'search',
      headerName: 'Search',
      maxWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <SearchIcon
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: 'green',
            },
          }}
          onClick={() => {
            const { ProductAttributeDefId, DataTypeId } = params.row;
            const productAttributeDataTypeName = attributeDataTypes.find((obj) => obj.AttributeDataTypeId === DataTypeId)?.Name;
            const url = `/product-info/products?pageSize=15&page=1&languageId=${languageId}`
              + `&productAttributeDefId=${ProductAttributeDefId}&dataTypeId=${DataTypeId}`
              + `&productAttributeDataTypeName=${productAttributeDataTypeName}`;
            window.open(url, '_blank');
          }}
        />
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      maxWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <DeleteForeverIcon
          sx={{
            cursor: 'pointer',
            '&:hover': {
              color: 'red',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            setOpenModal(true);
            setDeleteRow(params.row);
          }}
        />
      ),
    },
  ].filter(Boolean);

  const contextValue = React.useMemo(() => ({
    siteOptions,
    selectedRow,
    languageOptions,
    selectedLanguage,
    setSelectedLanguage,
    filterOpen,
    setFilterOpen,
    defKeyType,
    setDefKeyType,
    id,
    setId,
    idError,
    setIdError,
    dataType,
    setDataType,
    name,
    setName,
    attributeDataTypes,
    readOnly,
    setReadOnly,
    hidden,
    setHidden,
    mandatory,
    includeInSearchKeywords,
    setIncludeInSearchKeywords,
    setMandatory,
    defaultValue,
    setDefaultValue,
    globalVal,
    setGlobalVal,
    resetAllFilters,
    fetchData,
    includeProductCount,
    setIncludeProductCount,
    reRenderLogs,
    setReRenderLogs,
  }), [
    languageOptions,
    selectedLanguage,
    filterOpen,
    setFilterOpen,
    defKeyType,
    id,
    setId,
    idError,
    setIdError,
    dataType,
    setDataType,
    name,
    setName,
    attributeDataTypes,
    readOnly,
    setReadOnly,
    hidden,
    setHidden,
    mandatory,
    setMandatory,
    defaultValue,
    setDefaultValue,
    globalVal,
    setGlobalVal,
    resetAllFilters,
    fetchData,
    includeProductCount,
    setIncludeProductCount,
    reRenderLogs,
    setReRenderLogs]);

  return (
    <AttributeDefinitionsContext.Provider value={contextValue}>
      <div className="flex justify-center h-full gap-8 pt-2 m-8 mt-4 overflow-hidden">
        <div className="flex flex-col h-[calc(100vh-120px)] w-full overflow-auto pt-2 mr-5">
          <CreateModal open={openCreate} onClose={setOpenCreate} attributeDataTypes={attributeDataTypes} fetchData={fetchData} />
          <EditModal open={openEdit} onClose={setOpenEdit} attributeDataTypes={attributeDataTypes} fetchData={fetchData} />

          <Typography variant="h5" className="my-6 text-secondary-600">Attributes Definition</Typography>
          <Filter />
          <Stack className="flex justify-between w-full mt-5 mb-5" direction="row" alignItems="center">
            <Typography variant="h5" className="font-gothamlight">Products</Typography>
            <Stack direction="row" gap={4}>
              <Button variant="contained" onClick={() => setFilterOpen(!filterOpen)}>
                {filterOpen ? 'Close' : 'Filter'}
              </Button>
            </Stack>
          </Stack>
          <Box sx={{
            minHeight: '600px', width: '100%', mt: '1rem', overflowX: 'auto',
          }}
          >
            <DataGrid
              columns={columns}
              rows={rows}
              getRowId={(row) => row.ProductAttributeDefId}
              className="bg-white cursor-pointer max-w-[100%]"
              onRowClick={(params) => openEditModal(params.row)}
              slots={{
                pagination: () => CustomPagination(selectedPage, onChangeSelectedPage, rowsPerPage, onChangePageSize, pageAmount, totalRows),
                toolbar: () => CustomToolbar(setOpenCreate),
              }}
              rowsPerPageOptions={[15, 30, 50, 100, 200, 400, 600, 800, 1000]}
            />
          </Box>
          <Modal
            title="Delete Product Type"
            open={openModal}
            onClose={handleModalClose}
            buttonLabel="Confirm"
            buttonOnClick={handleDelete}
          >
            {deleteLoading ? (
              <div className="flex items-center justify-center h-16">
                <CircularProgress color="primary" />
              </div>
            ) : (
              <Typography className="text-center">
                {`Are you sure you want to delete ${deleteRow?.ProductAttributeDefKey}`}
              </Typography>
            )}
          </Modal>
          <Modal
            title="Product Count"
            open={openFaciaModal}
            onClose={() => setOpenFaciaModal(false)}
            desktopModalWidth="20%"
            desktopModalPadding="1rem"
          >
            {faciaProductCountLoading ? (
              <div className="flex items-center justify-center h-16">
                <CircularProgress color="primary" />
              </div>
            ) : (
              <div style={{ height: 200, width: '100%' }}>
                <DataGrid
                  rows={faciaProductRows}
                  columns={columnsFaciaProduct}
                  getRowId={(row) => row.Facia}
                  hideFooterPagination
                />
              </div>
            )}
          </Modal>
        </div>
        <AttributeLogs />
      </div>
    </AttributeDefinitionsContext.Provider>
  );
}

export default AttributeDefinitions;
