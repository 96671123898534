import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getUserGroupsCount from '../../api/private/users_management/gets/getUserGroupsCount';
import getUsersCount from '../../api/private/users_management/gets/getUsersCount';
import { fetchGroupList } from '../../redux/user/GroupListRedux';
import { fetchUserList } from '../../redux/user/UserListRedux';
import CreateNewUserModal from './modals/CreateNewUser';
import NewGroupModal from './modals/NewGroup';
import UserActivityLogs from './UserActivityLogs';
import UserGroupList from './UserGroupList';
import UserList from './UserList';

const useStyles = makeStyles((theme) => ({
  indicator: {
    backgroundColor: `${theme.palette.secondary[500]} !important`,
    height: '4px !important',
  },
  selected: {
    color: '#fff !important',
    opacity: '1 !important',
  },
  tabRoot: {
    color: '#fff !important',
    width: '33%',
  },
  table: {
    '& .MuiTableCell-root': {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    '&:last-child': {
      borderRight: 'none',
    },
    '&.MuiTableCell-head': {
      backgroundColor: theme.palette.grey[100],
    },
    width: '70%',
    '&:first-child': {
      width: '30%',
    },
  },
}));

function TabPanel({
  children,
  value,
  index,
  ...other
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p="8px">{children}</Box>}
    </div>
  );
}

TabPanel.defaultProps = {
  children: {},
  index: 0,
  value: 0,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function UserManagement() {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchTextPlaceHolder, setSearchTextPlaceHolder] = useState('Search Users');
  const [usersCount, setUsersCount] = useState(0);
  const [groupsCount, setGroupsCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [userTypes, setUserTypes] = React.useState({});
  const [userRights, setUserRights] = React.useState({});
  const [apiAccessTypes, setApiAccessTypes] = React.useState({});
  const [userPermission, setUserPermission] = React.useState({});

  const tabs = ['Users', 'Group', 'Logs'];
  const user = useSelector((state) => state.user.userProfile);
  const styles = useStyles();
  const handleOnTabChange = (event, newValue) => {
    setSearchText('');
    setTabValue(newValue);
    const tabNameItem = tabs[newValue];
    setSearchTextPlaceHolder(`Search ${tabNameItem}`);
  };

  const handleSearchChange = (event) => {
    const searchKey = event.target.value;
    setSearchText(searchKey); // Update the state with the new value
  };

  // get users count
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const userCount = await getUsersCount();
        setUsersCount(userCount.data);
        const groupCount = await getUserGroupsCount();
        setGroupsCount(groupCount.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchInitialData();
  }, []);

  const addNewUserHandler = async () => {
    setOpen(true);
  };
  const addNewgroupHandler = async () => {
    setOpenGroup((prev) => !prev);
  };
  const handleAddSuccess = async () => {
    const groupCount = await getUserGroupsCount();
    setGroupsCount(groupCount.data);
  };

  React.useEffect(() => {
    dispatch(fetchGroupList());
    dispatch(fetchUserList());
  }, []);

  return (
    <div className="pt-4 md:p-8">
      <Box
        sx={{
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <div className="flex flex-col justify-between mb-4 md:mb-8 md:flex-row md:items-center">
          <div>
            <Box sx={{ padding: '20px' }}>
              <Typography
                variant="h3"
                sx={{
                  color: '#384451',
                  marginBottom: 1,
                }}
              >
                User Management
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 3 }}>
                Manage user access and group permissions
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box>
                  <Box
                    sx={{
                      marginBottom: 0,
                      width: '300px',
                    }}
                  >
                    <TextField
                      variant="outlined"
                      value={searchText}
                      placeholder={searchTextPlaceHolder}
                      fullWidth
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: 30,
                          backgroundColor: '#E0F0F8',
                          height: '45px',
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
          <Stack direction="column" gap={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  borderRadius: '20px',
                  padding: 1,
                  textAlign: 'left',
                  width: 150,
                  border: '2px solid #5A9EA8',
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#5A9EA8',
                    fontWeight: 'bold',
                  }}
                >
                  Total Users
                </Typography>
                <Typography variant="h4">
                  <center>{usersCount}</center>
                </Typography>
              </Paper>
              <Paper
                elevation={1}
                sx={{
                  borderRadius: '20px',
                  padding: 1,
                  textAlign: 'left',
                  width: 150,
                  border: '2px solid #5A9EA8',
                }}

              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#5A9EA8',
                    fontWeight: 'bold',
                  }}
                >
                  Groups
                </Typography>
                <Typography variant="h4">
                  <center>{groupsCount}</center>
                </Typography>
              </Paper>
            </Box>
            <div className="pt-4 md:p-1">
              {openGroup && (
                <NewGroupModal
                  onAddSuccess={handleAddSuccess}
                  open={openGroup}
                  handleClose={() => {
                    setOpenGroup(false);
                  }}
                />
              )}
            </div>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<PersonAddIcon />}
                sx={{
                  borderRadius: '20px',
                  width: 150,
                  height: 40,
                  backgroundColor: '#384451',
                }}
                onClick={addNewUserHandler}
              >
                Add User
              </Button>
              {user.user_permission === 'Admin' && (
                <Button
                  variant="contained"
                  startIcon={<GroupAddIcon />}
                  sx={{
                    borderRadius: '20px',
                    width: 150,
                    height: 40,
                    backgroundColor: '#384451',
                  }}
                  onClick={addNewgroupHandler}
                >
                  Add Group
                </Button>
              )}

            </Box>
            {open && (
              <CreateNewUserModal
                open={open}
                handleClose={() => {
                  setOpen(false);
                }}
                userTypesMapping={userTypes}
                apiAccessTypesMapping={apiAccessTypes}
                userPermissionMapping={userPermission}
                usersCount={usersCount}
                setUsersCount={setUsersCount}
              />
            )}
          </Stack>
        </div>
        <Tabs
          value={tabValue}
          onChange={handleOnTabChange}
          classes={{
            indicator: styles.indicator,
            root: styles.selected,
          }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          className="flex flex-row"
          sx={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            overflow: 'hidden',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: '#384451',
            marginLeft: '20px',
          }}
        >
          {tabs.map((tab) => (
            <Tab
              {...a11yProps(tabValue)}
              key={tab}
              label={tab}
              classes={{
                root: styles.tabRoot,
                selected: styles.selected,
              }}
              sx={{ maxWidth: 'none' }}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <UserList
          search={searchText}
          userTypes={userTypes}
          userRights={userRights}
          apiAccessTypes={apiAccessTypes}
          setUserTypes={setUserTypes}
          setUserRights={setUserRights}
          setApiAccessTypes={setApiAccessTypes}
          userPermission={userPermission}
          setUserPermission={setUserPermission}
          usersCount={usersCount}
          onAddNewUser={addNewUserHandler}
          setUsersCount={setUsersCount}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <UserGroupList
          search={searchText}
          groupCount={groupsCount}
          onAddNewGroup={addNewgroupHandler}

        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <UserActivityLogs search={searchText} />
      </TabPanel>
    </div>
  );
}

export default UserManagement;
