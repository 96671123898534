import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import React, { useContext, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import patchAttribute from '../../../../../api/oc_product/productDataManagement/attributes/patch/patchAttributes';
import postLookupOptions from '../../../../../api/oc_product/productDataManagement/attributes/post/postLookupOptions';
import Modal from '../../../../../components/Modal';
import { AttributeDefinitionsContext } from '../../AttributeDefinitionsContext';

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL_STATE':
      return action.payload;
    case 'ID':
      return {
        ...state,
        id: action.payload,
      };
    case 'ATTRIBUTE_DEF_KEY':
      return {
        ...state,
        attributeDefKey: action.payload,
      };
    case 'NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'ENGLISH_NAME':
      return {
        ...state,
        eName: action.payload,
      };
    case 'SITES':
      return {
        ...state,
        sites: action.payload,
      };
    case 'ATTRIBUTE_DATA_TYPE':
      return {
        ...state,
        attributeDataType: action.payload,
      };
    case 'READ_ONLY':
      return {
        ...state,
        isReadOnly: action.payload,
      };
    case 'REQUIRED':
      return {
        ...state,
        isMandatory: action.payload,
      };
    case 'HIDDEN':
      return {
        ...state,
        isHidden: action.payload,
      };
    case 'INCLUDE_IN_SEARCH_KEYWORDS':
      return {
        ...state,
        includeInSearchKeywords: action.payload,
      };
    case 'LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

function CustomPagination(
  selectedPage,
  onChangeSelectedPage,
  pageSize,
  onChangePageSize,
  pageAmount,
) {
  return (
    <div className="flex justify-end gap-4">
      <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard" size="small" className="flex flex-row items-center mt-5 mb-0 sm:mt-0">
        <Typography variant="body2" className="mr-5">Rows per page:</Typography>
        <Select
          value={pageSize}
          onChange={onChangePageSize}
          autoWidth
          size="small"
          variant="standard"
        >
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={pageAmount}
        page={selectedPage}
        onChange={onChangeSelectedPage}
        size="small"
      />
    </div>
  );
}

function EditToolbar(props) {
  const {
    setRows, setRowModesModel,
  } = props;

  const handleClick = () => {
    const id = Math.random() * (1000000 - 1) + 1;
    const date = new Date();
    const formattedDate = date.toISOString();
    setRows((oldRows) => [{
      id,
      name: '',
      isDefaultValue: false,
      created: formattedDate,
      updated: formattedDate,
      totalUsageCount: 0,
      isNew: true,
    }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

function EditModal({
  onClose, open, attributeDataTypes, fetchData,
}) {
  const {
    selectedRow, siteOptions, setReRenderLogs, languageOptions,
  } = useContext(AttributeDefinitionsContext);
  const isLargeScreen = useMediaQuery('(min-width: 2000px)');
  const isMediumScreen = useMediaQuery('(min-width: 1200px)');

  const getLargeScreenWidth = () => {
    if (isLargeScreen) return '60%';
    if (isMediumScreen) return '60%';
    return '90%';
  };

  const largeScreenWidth = getLargeScreenWidth();
  const largeScreenHeight = isLargeScreen ? '80vh' : '90vh';
  const selectedSiteCode = useSelector((state) => state.user.loginResponse.selectedSite.SiteCode);

  const initialState = {
    id: 20240,
    attributeDefKey: '',
    name: '',
    eName: '',
    sites: [],
    attributeDataType: null,
    isReadOnly: null,
    isMandatory: null,
    isHidden: null,
    includeInSearchKeywords: null,
    language: null,
  };

  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);

  const [selectedPage, setSelectedPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [pageAmount, setPageAmount] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsLoading, setRowsLoading] = React.useState(false);

  const [formState, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [deletedLookupOptions, setDeletedLookupOptions] = React.useState([]);
  const [createdLookupOptions, setCreatedLookupOptions] = React.useState([]);
  const [updatedLookupOptions, setUpdatedLookupOptions] = React.useState([]);
  const [filterValue, setFilterValue] = React.useState('');
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [isDefaultValueFilter, setIsDefaultValueFilter] = React.useState(false);
  const [customSiteOptions, setCustomSiteOptions] = React.useState([]);

  const updateDisableBtn = () => {
    const isInEditMode = Object.values(rowModesModel).some((mode) => mode.mode === GridRowModes.Edit);
    setDisableBtn(isInEditMode);

    const englishLanguage = languageOptions.find((language) => language?.LanguageName === 'English');

    if (formState.language?.LanguageName !== englishLanguage?.LanguageName && formState.name === '') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };

  const handleClose = () => {
    onClose(false);
    setRows([]);
    setSelectedPage(1);
    setDeletedLookupOptions([]);
    setCreatedLookupOptions([]);
    setUpdatedLookupOptions([]);
    setDisableBtn(false);
    setIsDefaultValueFilter(false);
    setRowModesModel({});
  };

  const fetchLookupOptions = async () => {
    try {
      setRowsLoading(true);
      const res = await postLookupOptions(
        selectedPage,
        rowsPerPage,
        selectedRow?.ProductAttributeDefId,
        formState.language.LanguageId,
        filterValue.length > 0 ? filterValue : null,
        isDefaultValueFilter || null,
      );
      if (res) {
        const mappedRows = res.data?.Data.map((item) => ({
          id: Math.random() * (1000000 - 1) + 1,
          productAttributeLookupOptionId: item.ProductAttributeLookupOptionId,
          name: item.Value,
          isDefaultValue: item.IsDefaultValue,
          created: item.Created,
          updated: item.Updated,
          totalUsageCount: item.TotalUsageCount,
        }));
        setRows(mappedRows);
        setTotalRows(res.data?.Pagination.TotalCount);
        setPageAmount(res.data?.Pagination.PageCount);
      }
    } catch (error) {
      console.error('Error fetching attribute lookup options:', error);
    } finally {
      setRowsLoading(false);
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (params) => () => {
    setRowModesModel({ ...rowModesModel, [params.id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    const clickedRow = rows.find((row) => row.id === id);
    setRows(rows.filter((row) => row.id !== id));
    if (clickedRow.productAttributeLookupOptionId) {
      setDeletedLookupOptions((prevDeletedLookupOptions) => [...prevDeletedLookupOptions, clickedRow.productAttributeLookupOptionId]);
    } else {
      setCreatedLookupOptions((prevCreatedLookupValues) => prevCreatedLookupValues.filter((row) => row.id !== id));
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    // Update the rows state
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Update the appropriate state based on whether it's a new or existing row
    if (!newRow.productAttributeLookupOptionId) {
      const modifiedRow = {
        id: newRow.id,
        languageId: formState.language.LanguageId,
        value: newRow.name,
        isDefaultValue: newRow.isDefaultValue,
      };
      setCreatedLookupOptions((prevCreatedLookupOptions) => {
        const index = prevCreatedLookupOptions.findIndex((option) => option.id === modifiedRow.id);
        if (index !== -1) {
          const updatedOptions = [...prevCreatedLookupOptions];
          updatedOptions[index] = modifiedRow;
          return updatedOptions;
        }
        return [...prevCreatedLookupOptions, modifiedRow];
      });
    } else {
      setUpdatedLookupOptions((prevUpdatedLookupOptions) => {
        const modifiedRow = {
          productAttributeLookupOptionId: newRow.productAttributeLookupOptionId,
          value: newRow.name,
          isDefaultValue: newRow.isDefaultValue,
        };
        const index = prevUpdatedLookupOptions.findIndex((option) => option.productAttributeLookupOptionId === modifiedRow.productAttributeLookupOptionId);
        if (index !== -1) {
          const updatedOptions = [...prevUpdatedLookupOptions];
          updatedOptions[index] = modifiedRow;
          return updatedOptions;
        }
        return [...prevUpdatedLookupOptions, modifiedRow];
      });
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handlePatchAttribute = async () => {
    const langId = deletedLookupOptions.length > 0 || updatedLookupOptions.length > 0 || createdLookupOptions.length > 0
      ? formState.language.LanguageId : null;
    const langName = deletedLookupOptions.length > 0 || updatedLookupOptions.length > 0 || createdLookupOptions.length > 0
      ? formState.language.LanguageName : null;
    const body = {
      productAttributeDefId: selectedRow.ProductAttributeDefId,
      SiteId: siteId,
      LanguageId: langId,
      LanguageName: langName,
      productAttributeDef: {
        productAttributeDefKey: selectedRow.ProductAttributeDefKey,
        englishName: formState.eName,
        name: formState.name,
        isReadOnly: formState.isReadOnly,
        allowUpdate: !formState.isReadOnly,
        isMandatory: formState.isMandatory,
        isHidden: formState.isHidden,
        includeInSearchKeywords: formState.includeInSearchKeywords,
      },
      deletedProductAttributeLookupOptionIds: deletedLookupOptions,
      updatedAttributeLookupValues: updatedLookupOptions,
      createdAttributeLookupValues: createdLookupOptions.map((option) => ({
        languageId: option.languageId,
        value: option.value,
        isDefaultValue: option.isDefaultValue,
      })),
    };
    try {
      setLoading(true);
      const res = await patchAttribute(body);
      if (res) {
        toast.success('Attribute successfully updated');
      }
    } catch (error) {
      console.error('Error fetching attribute data types:', error);
    } finally {
      setReRenderLogs(true);
      handleClose();
      setLoading(false);
      fetchData();
    }
  };

  const onChangePageSize = (event) => {
    const newItemsPerPage = event.target.value;
    const newPageCount = Math.ceil(totalRows / newItemsPerPage);
    const newPage = Math.min(selectedPage, newPageCount);
    setRowsPerPage(newItemsPerPage);
    setSelectedPage(newPage);
  };

  const onChangeSelectedPage = (event, newPage) => {
    setSelectedPage(newPage);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 120,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: true,
    },
    {
      field: 'isDefaultValue',
      headerName: 'Is Default Value',
      type: 'boolean',
      maxWidth: 120,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: true,
    },
    {
      field: 'totalUsageCount',
      headerName: 'Total Usage Count',
      maxWidth: 130,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: false,
    },
    {
      field: 'created',
      headerName: 'Created',
      maxWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      renderCell: (params) => {
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
    {
      field: 'updated',
      headerName: 'Updated',
      maxWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      renderCell: (params) => {
        const dateSplit = (params.value).split('T');
        return (
          <Stack direction="column">
            <p>{dateSplit[0].split('-').reverse().join('/')}</p>
            <p>{dateSplit[1].split('.')[0]}</p>
          </Stack>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      maxWidth: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(params)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params.id)}
              color="inherit"
            />,
          ];
        }

        const actions = [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.id)}
            color="inherit"
          />,
        ];

        return actions;
      },
    },

  ];

  React.useEffect(() => {
    if (open && selectedRow) {
      fetchLookupOptions();
    }
    updateDisableBtn();
  }, [selectedPage, rowsPerPage]);

  React.useEffect(() => {
    updateDisableBtn();
  }, [rowModesModel, formState]);

  useEffect(() => {
    if (selectedRow && open) {
      const siteObj = siteOptions?.find((site) => site.SiteId === selectedRow.SiteId);
      const defLang = languageOptions.find((obj) => obj.LanguageId === languageId);
      const state = {
        id: selectedRow?.ProductAttributeDefId,
        attributeDefKey: selectedRow?.ProductAttributeDefKey,
        name: selectedRow?.LocalName ? selectedRow?.LocalName : '',
        eName: selectedRow?.EnglishName,
        sites: siteObj ? [siteObj] : [{ Name: 'Global' }],
        attributeDataType: attributeDataTypes?.find((obj) => obj.AttributeDataTypeId === selectedRow?.DataTypeId),
        isReadOnly: selectedRow?.IsReadOnly,
        isMandatory: selectedRow?.IsMandatory,
        isHidden: selectedRow?.IsHidden,
        includeInSearchKeywords: selectedRow?.IncludeInSearchKeywords,
        language: defLang,
      };
      dispatch({ type: 'INITIAL_STATE', payload: state });
      fetchLookupOptions();
    }
  }, [selectedRow, open]);

  React.useEffect(() => {
    if (selectedRow) {
      fetchLookupOptions();
    }
  }, [filterValue, isDefaultValueFilter, formState.language]);

  React.useEffect(() => {
    if (languageId && languageOptions.length > 0) {
      const defLang = languageOptions.find((obj) => obj.LanguageId === languageId);
      dispatch({ type: 'LANGUAGE', payload: defLang });
    }
  }, [languageId, languageOptions]);

  React.useEffect(() => {
    if (selectedSiteCode !== 'OC') {
      if (siteId && siteOptions.length > 0) {
        let siteOptionss = [];
        if (selectedSiteCode !== 'OC') {
          setCustomSiteOptions(siteOptions.filter((row) => row.SiteCode !== 'OC'));
          siteOptionss = siteOptions.filter((row) => row.SiteCode !== 'OC');
        }
        const defSite = siteOptionss.find((obj) => obj.SiteId === siteId);
        // setSelectedSites([defSite]);
        dispatch({ type: 'SITES', payload: [defSite.SiteId] });
      }
    }
  }, [siteOptions]);
  const modalStyles = {
    width: (selectedRow?.DataTypeId === 9 || selectedRow?.DataTypeId === 8)
      ? largeScreenWidth
      : '60%',
    height: (selectedRow?.DataTypeId === 9 || selectedRow?.DataTypeId === 8)
      ? largeScreenHeight
      : '90vh',
  };
  return (
    <Modal
      open={open}
      title="Edit Attribute Definition & Values"
      onClose={handleClose}
      desktopModalWidth={modalStyles.width}
      desktopModalHeight={modalStyles.height}
      containerWidth="xl"
      buttonLoading={loading}
      disableButton={disableBtn}
    >

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="ID"
            id="id"
            value={formState?.id || ''}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Definition Key"
            id="definitionKey"
            value={formState?.attributeDefKey || ''}
            disabled
          />
        </Grid>

        {
          formState.language
          && (formState.language.LanguageName !== 'English') && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                id="name"
                variant="outlined"
                value={formState?.name}
                onChange={(e) => {
                  dispatch({ type: 'NAME', payload: e.target.value });
                }}
                error={formState.name === ''}
                helperText={
                  formState.name === '' && 'This field cannot be empty'
                }
              />
            </Grid>
          )
        }

        <Grid item xs={12}>
          <TextField
            fullWidth
            label={formState.language && (formState.language.LanguageName !== 'English') ? 'English Name' : 'Name'}
            id="englishName"
            variant="outlined"
            value={formState?.eName}
            onChange={(e) => dispatch({ type: 'ENGLISH_NAME', payload: e.target.value })}
            error={formState.eName === ''}
            helperText={
              formState.eName === '' && 'This field cannot be empty'
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="sites-tags"
            options={customSiteOptions}
            getOptionLabel={(option) => option?.Name || ''}
            value={formState?.sites}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sites"
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            id="attribute-data-types"
            options={attributeDataTypes}
            disabled
            getOptionLabel={(option) => option.Description || ''}
            filterSelectedOptions
            value={formState?.attributeDataType}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Attribute Data Types"
              />
            )}
          />
        </Grid>

        <Grid container item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={formState?.isReadOnly} onChange={(e) => dispatch({ type: 'READ_ONLY', payload: e.target.checked })} />}
              disableTypography
              sx={{ fontSize: '14px' }}
              label="Read Only"
              id="readOnlyCheckBox"
            />
            <FormControlLabel
              control={<Checkbox checked={formState?.isMandatory} onChange={(e) => dispatch({ type: 'REQUIRED', payload: e.target.checked })} />}
              disableTypography
              sx={{ fontSize: '14px' }}
              label="Required"
              id="required"
            />
            <FormControlLabel
              control={<Checkbox checked={formState?.isHidden} onChange={(e) => dispatch({ type: 'HIDDEN', payload: e.target.checked })} />}
              disableTypography
              sx={{ fontSize: '14px' }}
              label="Hidden"
              id="hidden"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={formState?.includeInSearchKeywords}
                  onChange={(e) => dispatch({ type: 'INCLUDE_IN_SEARCH_KEYWORDS', payload: e.target.checked })}
                />
              )}
              disableTypography
              sx={{ fontSize: '14px' }}
              label="Include in search keywords"
              id="include-in-search-keywords"
            />
          </FormGroup>
        </Grid>
      </Grid>
      {(selectedRow?.DataTypeId === 9 || selectedRow?.DataTypeId === 8) && (
        <>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="mx-10"
          />
          <div className="w-[100%] overflow-auto max-h-[calc(100vh-150px)]">
            <div className="flex items-center mb-5">
              <TextField
                id="filter-field"
                label="Filter"
                className="mr-5"
                size="small"
                value={filterValue}
                onChange={(e) => {
                  setFilterValue(e.target.value);
                }}
              />
              <FormGroup row>
                <FormControlLabel
                  control={<Checkbox checked={isDefaultValueFilter} onChange={() => setIsDefaultValueFilter(!isDefaultValueFilter)} />}
                  disableTypography
                  sx={{ fontSize: '14px' }}
                  label="Is Default Value"
                  id="default-value-filter"
                />
              </FormGroup>
              <Autocomplete
                options={languageOptions.map((obj) => ({
                  ...obj,
                  Name: obj.LanguageName,
                }))}
                value={formState?.language}
                renderInput={(params) => (
                  <TextField {...params} label="Language" />
                )}
                getOptionLabel={(option) => option.LanguageName}
                size="small"
                onChange={(e, value) => {
                  dispatch({ type: 'LANGUAGE', payload: value });
                }}
                optionKey="LanguageName"
                className="w-[250px]"
              />
            </div>
            <DataGrid
              columns={columns}
              rows={rows}
              className="bg-white cursor-pointer h-[450px]"
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slots={{
                pagination: () => CustomPagination(selectedPage, onChangeSelectedPage, rowsPerPage, onChangePageSize, pageAmount, totalRows),
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { rows, setRows, setRowModesModel },
              }}
              loading={rowsLoading}
            />
          </div>
        </>
      )}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <Button onClick={handleClose} color="inherit" variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button onClick={handlePatchAttribute} color="success" variant="contained" disabled={disableBtn} loading={loading}>
          Save
        </Button>
      </Box>
    </Modal>
  );
}

export default EditModal;
