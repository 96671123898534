/* eslint-disable no-nested-ternary */
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import patchCategoryGroupingName from '../../../../api/oc_product/productDataManagement/category/patch/patchCategoryGroupingName';
import patchCategoryName from '../../../../api/oc_product/productDataManagement/category/patch/patchCategoryName';
import { CategoryContext } from '../CategoryContext';

function CategoryDetails({
    categoryDetails, categoryName, categoryId, detailsLoading, setCategoriesLoading, setCategoryNameUpdated,
}) {
    const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
    const { setReFetchLogs } = useContext(CategoryContext);

    const siteCode = useSelector((state) => state?.user?.loginResponse?.selectedSite?.SiteCode);
    const omsId = useSelector((state) => state?.user?.loginResponse?.selectedSite?.OmsId);

    const [editCategoryName, setEditCategoryName] = React.useState(false);
    const [newCategoryName, setNewCategoryName] = React.useState(null);
    const [editCategoryGroupingName, setEditCategoryGroupingName] = React.useState(false);
    const [newCategoryGroupingName, setNewCategoryGroupingName] = React.useState(null);
    const [nameLoading, setNameLoading] = React.useState(false);
    const [groupingNameLoading, setGroupingNameLoading] = React.useState(false);

    const handleProductCountClick = () => {
        if (!categoryDetails?.ProductCounts || !categoryId) return;
        const omsParam = omsId ? `&omsId=${omsId}` : '';
        const url = `/product-info/products?siteCode=${siteCode}&pageSize=15&page=1${omsParam}&categories=${encodeURIComponent(categoryId)}`;
        window.open(url, '_blank');
    };

    const handleEditCategoryName = (event) => {
        event.preventDefault();
        setEditCategoryName(true);
    };

    const handleEditCategoryGroupName = (event) => {
        event.preventDefault();
        setEditCategoryGroupingName(true);
    };

    const handleSaveCategoryName = async (event) => {
        event.preventDefault();
        try {
            setEditCategoryName(false);
            setCategoriesLoading(true);
            setCategoryNameUpdated(true);
            setNameLoading(true);
            const res = await patchCategoryName(categoryId, siteId, newCategoryName);
            if (res.data) {
                toast.success('Category name successfully changed');
            }
        } catch (err) {
            setCategoriesLoading(false);
            setCategoryNameUpdated(false);
            setNameLoading(false);
            console.error(err);
        } finally {
            setCategoriesLoading(false);
            setCategoryNameUpdated(false);
            setNameLoading(false);
            setReFetchLogs(true);
        }
    };

    const handleSaveCategoryGroupName = async (event) => {
        event.preventDefault();
        try {
            setEditCategoryGroupingName(false);
            setGroupingNameLoading(true);
            const res = await patchCategoryGroupingName(categoryId, siteId, newCategoryGroupingName);
            if (res.data) {
                toast.success('Category grouping name successfully changed');
            }
        } catch (err) {
            setGroupingNameLoading(false);
            console.error(err);
        } finally {
            setGroupingNameLoading(false);
            setReFetchLogs(true);
        }
    };

    const handleCancelCategoryName = (event) => {
        event.preventDefault();
        setNewCategoryName(categoryName);
        setEditCategoryName(false);
    };

    const handleCancelCategoryGroupName = (event) => {
        event.preventDefault();
        setNewCategoryGroupingName(categoryDetails.CategoryGroupingName);
        setEditCategoryGroupingName(false);
    };

    React.useEffect(() => {
        if (categoryName) setNewCategoryName(categoryName);
    }, [categoryName]);

    React.useEffect(() => {
        if (categoryDetails) setNewCategoryGroupingName(categoryDetails.CategoryGroupingName);
    }, [categoryDetails]);

    return (
        <div>
            {detailsLoading ? (
                <Card variant="outlined" className="flex flex-col items-start justify-start w-full p-4 min-h-72">
                    Loading...
                </Card>
            ) : (
                categoryDetails ? (
                    <Card variant="outlined" className="flex flex-col items-start justify-start w-full p-4 min-h-72">
                        {nameLoading && <LinearProgress className="w-full rounded-full" />}
                        {!editCategoryName ? (
                            <Typography variant="h6" className="flex items-center mb-4">
                                {newCategoryName}
                                <IconButton
                                    onMouseDown={handleEditCategoryName}
                                    className="ml-2 cursor-pointer"
                                >
                                    <CreateIcon />
                                </IconButton>
                            </Typography>
                        ) : (
                            <div className="flex flex-row items-center mb-4">
                                <TextField
                                    id="category-name"
                                    label="Category Name"
                                    variant="outlined"
                                    size="small"
                                    value={newCategoryName}
                                    onChange={(e) => setNewCategoryName(e.target.value)}
                                />
                                <div className="flex flex-row">
                                    <IconButton onMouseDown={(e) => e.preventDefault()} onMouseUp={handleSaveCategoryName}>
                                        <CheckIcon className="text-success-600" />
                                    </IconButton>
                                    <IconButton onMouseDown={(e) => e.preventDefault()} onMouseUp={handleCancelCategoryName}>
                                        <CloseIcon className="text-error-600" />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                        <label className="text-lg bold" htmlFor="category_id">
                            Category ID
                        </label>
                        <Typography id="category_id" variant="body1" className="mb-6" gutterBottom>
                            {categoryId}
                        </Typography>
                        <label className="text-lg bold" htmlFor="ids">
                            Remote ID - Source
                        </label>
                        <div className="mb-6">
                            {categoryDetails?.RemoteIdSource?.split(',').map((part) => (
                                <Typography id="ids" variant="body1" gutterBottom>
                                    {part.trim()}
                                </Typography>
                            ))}
                        </div>
                        <label className="text-lg bold" htmlFor="translations">
                            Translations
                        </label>
                        <Typography id="translations" variant="body1" className="mb-6" gutterBottom>
                            {categoryDetails?.Translations}
                        </Typography>
                        <label className="text-lg bold" htmlFor="count">
                            Category Grouping Name
                        </label>
                        {groupingNameLoading && <LinearProgress className="w-full rounded-full" />}
                        {!editCategoryGroupingName ? (
                            <Typography variant="body1" className="flex items-center mb-4">
                                {newCategoryGroupingName?.length > 0 ? newCategoryGroupingName : 'Empty'}
                                <IconButton
                                    onMouseDown={handleEditCategoryGroupName}
                                    className="ml-2 cursor-pointer"
                                >
                                    <CreateIcon />
                                </IconButton>
                            </Typography>
                        ) : (
                            <div className="flex flex-row items-center mt-4 mb-4">
                                <TextField
                                    id="category-name"
                                    label="Category Grouping Name"
                                    variant="outlined"
                                    size="small"
                                    value={newCategoryGroupingName}
                                    onChange={(e) => setNewCategoryGroupingName(e.target.value)}
                                />
                                <div className="flex flex-row">
                                    <IconButton onMouseDown={(e) => e.preventDefault()} onMouseUp={handleSaveCategoryGroupName}>
                                        <CheckIcon className="text-success-600" />
                                    </IconButton>
                                    <IconButton onMouseDown={(e) => e.preventDefault()} onMouseUp={handleCancelCategoryGroupName}>
                                        <CloseIcon className="text-error-600" />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                        <label className="text-lg bold" htmlFor="count">
                            Product Count
                        </label>
                        <Typography
                            id="count"
                            variant="body1"
                            className="mb-6 text-blue-600 cursor-pointer"
                            gutterBottom
                            onClick={handleProductCountClick}
                        >
                            {categoryDetails?.ProductCounts}
                        </Typography>
                    </Card>
                ) : (
                    <Card variant="outlined" className="w-full p-4 h-72">
                        <Typography>Select category to see details.</Typography>
                    </Card>
                )
            )}
        </div>
    );
}

export default CategoryDetails;
