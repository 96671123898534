import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';

import deleteAttributeValuesFromAllProducts
  from '../../../api/oc_product/bulk/bulk_process/deleteAttributeValues/deletes/deleteAttributeValuesFromAllProducts';
import getSiteAttributes from '../../../api/oc_product/bulk/bulk_process/deleteAttributeValues/gets/getAllSiteLevelAttributes';
import getAttributeProductsCount from '../../../api/oc_product/bulk/bulk_process/deleteAttributeValues/gets/getAttributeProductsCount';
import getLanguages from '../../../api/oc_product/product/languages/gets/getLanguages';
import VirtualizedAutocomplete from '../../../components/VirtualizedAutocomplete';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONTENT_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'ATTRIBUTE':
      return {
        ...state,
        attribute: action.payload,
      };
    case 'RESET':
      return action.payload;
    default:
      return state;
  }
};
const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

function DeleteAttributeValuesFromAllProducts({ refreshReports }) {
  const siteId = useSelector((state) => state.user.loginResponse.selectedSite.SiteId);
  const languageId = useSelector((state) => state.user.loginResponse.selectedSite.LanguageId);

  const initialState = {
    language: null,
    attribute: null,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const [languagesOptions, setLanguagesOptions] = React.useState([]);
  const [siteAttributeOptions, setSiteAttributeOptions] = React.useState([]);
  const [totalProductsCount, setTotalProductsCount] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    getLanguages(siteId).then((res) => setLanguagesOptions(res.data));
    getSiteAttributes(siteId)
      .then((res) => {
        res.data.sort((a, b) => ((a.Name > b.Name) ? 1 : -1));
        setSiteAttributeOptions(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    if (state.attribute) {
      getAttributeProductsCount(siteId, state.attribute.ProductAttributeDefId, state.language).then((res) => setTotalProductsCount(res.data));
    }
  }, [state.attribute]);

  React.useEffect(() => {
    if (state.language) {
      dispatch({ type: 'ATTRIBUTE', value: initialState.attribute });
      getSiteAttributes(siteId)
        .then((res) => {
          res.data.sort((a, b) => ((a.Name > b.Name) ? 1 : -1));
          setSiteAttributeOptions(res.data);
        })
        .catch((err) => console.error(err));
    } else {
      dispatch({ type: 'ATTRIBUTE', value: initialState.attribute });
    }
  }, [state.language]);

  React.useEffect(() => {
    dispatch({ type: 'RESET', payload: initialState });
    getLanguages(siteId).then((res) => {
      setLanguagesOptions(res.data);
      dispatch({ type: 'CONTENT_LANGUAGE', payload: languageId });
    });
  }, [siteId]);

  const handleBulk = (e) => {
    e.preventDefault();
    if (totalProductsCount > 0) { // delete
      deleteAttributeValuesFromAllProducts({
        siteId,
        sourceId: 0,
        attributeDefId: state.attribute.ProductAttributeDefId,
        languageId: state.language,
        totalProductsCount,
      })
        .then(() => {
          refreshReports(true);
          setOpenDialog(false);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="flex items-start justify-start w-full gap-10 mt-4 lg:flex-col xl:flex-row">
      <div className="flex flex-col">
        <div
          className="mb-8 bg-white border rounded-lg right-8"
        />
        <section>
          <div className="flex-1 p-4 mb-6 text-left bg-white border-2 rounded w-96 border-error-600">
            <Typography gutterBottom className="font-gothammedium">
              Important
            </Typography>
            <ul>
              <li className="pt-2">AttributedDef values (all) will be removed from all assigned products.</li>
            </ul>
          </div>
        </section>
      </div>

      <Container maxWidth="md">
        <section className="w-full">
          <section className="flex flex-col max-w-sm gap-8">
            <div key="step1">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    1
                  </Typography>
                </div>
                <Typography variant="body2">
                  Select Content Language
                </Typography>
              </div>

              <Box sx={{ paddingTop: '1rem' }}>
                <VirtualizedAutocomplete
                  options={languagesOptions.map((obj) => ({
                    ...obj,
                    Name: obj.LanguageName,
                  }))}
                  textFieldLabel="Content Language"
                  textFieldVariant="outlined"
                  value={languagesOptions.find((obj) => obj?.LanguageId === state.language) || null}
                  getOptionLabel={(option) => option.LanguageName}
                  onSelectedValue={(value) => dispatch({ type: 'CONTENT_LANGUAGE', payload: value?.LanguageId })}
                  optionKey="LanguageName"
                />
              </Box>
            </div>
            <div key="step1">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    2
                  </Typography>
                </div>
                <Typography variant="body2">
                  Select Action
                </Typography>
              </div>

              <Box sx={{ paddingTop: '1rem' }}>
                <FormControl fullWidth>
                  <InputLabel id="action-label">Action</InputLabel>
                  <Select
                    labelId="action-label"
                    id="action-select"
                    label="Action"
                    onChange={(e) => e.target.value}
                    defaultValue={4}
                    disabled
                  >
                    <MenuItem value={4}>Delete</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div key="step2">
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-4 h-4 p-3 text-white rounded-full bg-secondary-500">
                  <Typography variant="caption" className="font-gothammedium">
                    3
                  </Typography>
                </div>
                <Typography variant="body2">Select Attribute</Typography>
              </div>

              <Box sx={{ paddingTop: '1rem' }}>
                <VirtualizedAutocomplete
                  key="Attribute"
                  options={siteAttributeOptions}
                  textFieldLabel="Attribute"
                  textFieldVariant="outlined"
                  getOptionLabel={(option) => option.Name}
                  onSelectedValue={(value) => dispatch({ type: 'ATTRIBUTE', payload: value })}
                  optionKey="Name"
                  value={siteAttributeOptions.find((obj) => obj.ProductAttributeDefId === state.attribute) || null}
                />
              </Box>
            </div>
          </section>
          <section className="flex flex-col max-w-sm gap-8">
            <div className="flex flex-col gap-4">
              <div className="w-full">
                <div className="relative float-right w-max">
                  <Button
                    variant="contained"
                    className="z-10"
                    type="submit"
                    disabled={!state.attribute}
                    onClick={handleClickOpenDialog}
                    sx={{ marginTop: 3 }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Container>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          <Typography color="textPrimary" align="center" variant="h4">
            <Chip variant="outlined" color="primary" label={state.attribute?.Name} size="small" />
            <br />
            Delete attribute values
            {' '}
            <br />
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography align="center" color="red" variant="h5">

              <div style={{ color: 'black' }}>
                {(totalProductsCount === 0) ? 'There is no products assgin to this arribute!' : 'This will remove the selected attribute values from'}
              </div>
              {totalProductsCount}
              {' '}
              Products

            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: '20px', paddingRight: '20px' }}>
          <Button sx={{ cursor: 'pointer' }} variant="outlined" color="info" onClick={handleCloseDialog}>Cancel</Button>
          <Button
            sx={{ cursor: 'pointer' }}
            variant="contained"
            color="primary"
            onClick={handleBulk}
            disabled={totalProductsCount === 0}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteAttributeValuesFromAllProducts;
