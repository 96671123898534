import '../../../../../showScrollbars.css';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import React from 'react';

function ProductIdentifiers({ productId, plus, parentLoading }) {
  return (
    <div
      className="relative h-[101%] max-h-[180px] w-full pl-4"
    >
      <Typography variant="body1" gutterBottom className="font-gothammedium">
        Product Identifiers
      </Typography>
      <table className="max-h-full mt-3 overflow-hidden">
        <tbody>
          <tr>
            <td className="pb-4">Product Id:</td>
            <td className="pb-4 pl-8">
              {parentLoading ? (
                <Skeleton width="6rem" />
              ) : productId}
            </td>
          </tr>
          <tr>
            <td className="pb-1">PLUs:</td>
          </tr>
          {parentLoading ? (
            <>
              <tr>
                <td aria-label="Skeleton" className="pb-2">
                  <Skeleton width="6rem" />
                </td>
                <td aria-label="Skeleton" className="pb-2 pl-8">
                  <Skeleton width="6rem" />
                </td>
              </tr>
              <tr>
                <td aria-label="Skeleton" className="pb-2">
                  <Skeleton width="6rem" />
                </td>
                <td aria-label="Skeleton" className="pb-2 pl-8">
                  <Skeleton width="6rem" />
                </td>
              </tr>
              <tr>
                <td aria-label="Skeleton" className="pb-2">
                  <Skeleton width="6rem" />
                </td>
                <td aria-label="Skeleton" className="pb-2 pl-8">
                  <Skeleton width="6rem" />
                </td>
              </tr>
            </>
          ) : plus?.map((obj) => (
            <tr key={obj.Source}>
              <td className="pb-2">{obj.Source}</td>
              <td className="pb-2 pl-8">{obj.Plu}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductIdentifiers;
