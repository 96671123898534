import getRequest from '../../../../getRequest';

export default async function getFaciaProductCount(productAttributeDefId, isSingleLine) {
  const endpoint = `/oc-data-management/v1/attributes/attribute-facia-product-count?productAttributeDefId=${productAttributeDefId}&isSingleLine=${isSingleLine}`;

  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get facia product count')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
