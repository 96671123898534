import './deployment.css';

import React from 'react';

import Logo from '../../assets/PrismIcon.png';
import settingImage from './Cogs.png';

function Deployment() {
  return (
    <div className="maintenance-container">
      <div className="logo">
        <img className="mb-2 w-20" src={Logo} alt="PRISM" />
        <h1 className="center-text text-2xl">PRISM</h1>
      </div>
      <h1 className="flex items-center text-5xl items-base mb-1">
        We are down for maintenance
        <img className="gear-image" src={settingImage} alt="PRISM" />
      </h1>
      <p className="mb-6 text-1xl ">
        Scheduled down time 12:00 - 13.30
      </p>
      <p className="text-2xl ">
        Apologies for any inconvenience,
      </p>
      <p className="text-2xl ">
        We will be back shortly
      </p>
      <div className="contact-info ">
        <p>
          For support, contact - support@jdplc.com
        </p>
      </div>
    </div>
  );
}

export default Deployment;
