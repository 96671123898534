import Cookies from 'js-cookie';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router';
import { Navigate } from 'react-router-dom';

import { setLocalStorage } from '../helper/LocalStorage';
// import { clearPending } from '../axios/pendingRequests';
import Layout from '../layout/Layout';
// import Approve from '../pages/bulk_operations/approve/Approve';
// import { default as BulkOperationsAttributes } from '../pages/bulk_operations/attribu
// tes/Attributes';
import BulkContainer from '../pages/bulk_operations/BulkContainer';
// import Campaign from '../pages/bulk_operations/campaign/Campaign';
// import Categories from '../pages/bulk_operations/categories/Categories';
// import Collection from '../pages/bulk_operations/collection/Collection';
// import MultiSelectAttributes from '../pages/bulk_operations/multiselect_attributes/Multi
// SelectAttributes';
// import PrimaryCategory from '../pages/bulk_operations/primary_category/PrimaryCategory';
// import ProductName from '../pages/bulk_operations/product_name/ProductName';
// import ProductType from '../pages/bulk_operations/product_type/ProductType';
// import ReleaseDates from '../pages/bulk_operations/release_dates/ReleaseDates';
// import SingleAttributes from '../pages/bulk_operations/single_attributes/SingleAttributes';
// import VAT from '../pages/bulk_operations/vat/VAT';
import Dashboard from '../pages/dashboard/Dashboard';
import AttributeDefinitions from '../pages/data_management/attributes_definitions/AttributeDefinitions';
import Brands from '../pages/data_management/brands/Brands';
import BrandGroupingContainer from '../pages/data_management/brands/grouping/BrandGroupingContainer';
import Category from '../pages/data_management/category/Category';
import ColourMatching from '../pages/data_management/colourMatching/ColourMatching';
import { default as ProductTypeAttributes } from '../pages/data_management/product_types/attributes/Attributes';
import ProductTypes from '../pages/data_management/product_types/ProductTypes';
import SizeMapping from '../pages/data_management/size_mapping/SizeMapping';
// import Pricing from '../pages/dropship_management/pricing/Pricing';
// import VatCode from '../pages/dropship_management/vat_code/VatCode';
import ProductWizard from '../pages/dropship_management/wizards/product_wizard/ProductWizard';
import ImageChecker from '../pages/image_checker/ImageViewer';
import Maintenance from '../pages/maintenance/Maintenance';
import MarginThreshold from '../pages/margin_threshold/MarginThreshold';
import NotFound from '../pages/NotFound';
import Dates from '../pages/product_info/dates/Dates';
import MediaCheck from '../pages/product_info/media_check/MediaCheck';
import NewInDate from '../pages/product_info/new_in_date/NewInDate';
import { default as ProductInfoPricing } from '../pages/product_info/pricing/Pricing';
import Product from '../pages/product_info/products/product/Product';
import Products from '../pages/product_info/products/ProductsTable';
import SearchByAttribute from '../pages/product_info/search_by_attribute/SearchByAttribute';
import SkuMerging from '../pages/product_info/sku_merging/SkuMerging';
import ReleaseNotes from '../pages/release_notes/ReleaseNotes';
import Indexing from '../pages/settings/indexes/Indexing';
import Styles from '../pages/styles/Styles';
import UserManagement from '../pages/users/UserManagement';
import { setUser } from '../redux/user/User';
import jwtDecoder from '../utils/jwtDecoder';
// import User from '../redux/user/User';

function setTokens(refreshToken, tokenExpires) {
  setLocalStorage('RefreshToken', refreshToken);
  setLocalStorage('AccessTokenExpires', tokenExpires);
}

const currentURL = window.location.href.replace(/\/$/, '');
const { port } = window.location;
const isLocalURL = currentURL === `http://localhost:${port}/login-local`
|| currentURL === 'https://local.prism.jdplc.com.127.0.0.1.nip.io/login-local';

function handleMount() {
  const token = Cookies.get('AccessToken');
  const dispatch = useDispatch();

  if (!isLocalURL) {
    const hasRunLoginLogic = sessionStorage.getItem('hasRunLoginLogic');
    const logoutEvent = localStorage.getItem('logout-event');
    const refreshToken = localStorage.getItem('RefreshToken');
    const validToUtc = localStorage.getItem('AccessTokenExpires');

    const updateTokens = () => {
      const RefreshToken = Cookies.get('RefreshToken');
      const ValidToUtc = Cookies.get('AccessTokenExpires');
      setTokens(RefreshToken, ValidToUtc);
    };

    if (token && !hasRunLoginLogic && !logoutEvent) {
      const jwtDecoded = jwtDecoder(token);
      dispatch(setUser(jwtDecoded));
      updateTokens();
      sessionStorage.setItem('hasRunLoginLogic', 'true');
    } else if (token && (!refreshToken || !validToUtc)) {
      updateTokens();
    }
  }

  const user = useSelector((state) => state.user);
  const hasAccess = token?.length > 0 && user?.userProfile?.typ === 'PrivateApi';

  const maintenanceMode = false;

  if (maintenanceMode) {
    return <Maintenance />;
  }

  return hasAccess ? <Layout /> : <Navigate to="/login" />;
}

// function isAdmin(user) {
//   const token = Cookies.get('AccessToken');

//   const hasAccess = token?.length > 0
//     && user?.userProfile?.typ === 'PrivateApi'
//     && (user?.userProfile?.user_right === 'Admin'
//       || user?.userProfile?.user_right === 'Developer');

//   return hasAccess ? <Outlet /> : <Navigate to="/" />;
// }

// This is to allow users for Live testing without showing other none released pages.
function isAdminOrAppManager(user) {
  const token = Cookies.get('AccessToken');

  const hasAccess = token?.length > 0
    && user?.userProfile?.typ === 'PrivateApi'
    && (user?.userProfile?.user_permission === 'Admin'
      || user?.userProfile?.user_permission === 'GroupAdmin'
      || user?.userProfile?.user_permission === 'User'
      || user?.userProfile?.user_permission === 'Viewer');

  return hasAccess ? <Outlet /> : <Navigate to="/" />;
}
function isAdminBulk(user) {
  const token = Cookies.get('AccessToken');

  const hasAccess = token?.length > 0
    && user?.userProfile?.typ === 'PrivateApi'
    && (user?.userProfile?.user_permission === 'Admin'
      || user?.userProfile?.user_permission === 'GroupAdmin'
      || user?.userProfile?.user_permission === 'User'
      || user?.userProfile?.user_permission === 'Viewer');

  return hasAccess ? <BulkContainer /> : <Navigate to="/" />;
}

function isAdminImageChecker(user) {
  const token = Cookies.get('AccessToken');

  const hasAccess = token?.length > 0
    && user?.userProfile?.typ === 'PrivateApi'
    && (user?.userProfile?.user_permission === 'Admin'
      || user?.userProfile?.user_permission === 'GroupAdmin'
      || user?.userProfile?.user_groupName === 'ImageChecker');

  return hasAccess;
}

function MainRoutes() {
  const selectedSite = useSelector((state) => state.user.loginResponse.selectedSite);
  const user = useSelector((state) => state.user);

  const renderWithSiteCheck = (component) => (
    selectedSite?.SiteCode === 'JQ' ? <Navigate to="/" /> : component
  );

  return {
    path: '/',
    element: handleMount(),
    children: [
      { index: true, element: <Dashboard /> },
      // { index: true, element: <Navigate to="/dropship/wizards/product" /> },
      {
        path: 'product-info',
        element: isAdminOrAppManager(user),
        children: [
          {
            path: 'products',
            element: <Outlet />,
            children: [
              { index: true, element: renderWithSiteCheck(<Products />) },
              { path: ':id', element: renderWithSiteCheck(<Product />) },
            ],
          },
          { path: 'media-check', element: renderWithSiteCheck(<MediaCheck />) },
          { path: 'sku-merging', element: renderWithSiteCheck(<SkuMerging />) },
          { path: 'dates', element: renderWithSiteCheck(<Dates />) },
          { path: 'sku-merging', element: renderWithSiteCheck(<SkuMerging />) },
          { path: 'new-in-date', element: renderWithSiteCheck(<NewInDate />) },
          { path: 'search-by-attributes', element: renderWithSiteCheck(<SearchByAttribute />) },
          { path: 'pricing', element: renderWithSiteCheck(<ProductInfoPricing />) },
        ],
      },
      {
        path: 'bulk-operations',
        element: renderWithSiteCheck(isAdminBulk(user)),
        // children: [
        //   { path: 'approve', element: <Approve /> },
        //   { path: 'product-name', element: <ProductName /> },
        //   { path: 'attributes', element: <BulkOperationsAttributes /> },
        //   { path: 'campaign', element: <Campaign /> },
        //   { path: 'categories', element: <Categories /> },
        //   { path: 'collection', element: <Collection /> },
        //   { path: 'multi-select', element: <MultiSelectAttributes /> },
        //   { path: 'product-type', element: <ProductType /> },
        //   { path: 'primary-category', element: <PrimaryCategory /> },
        //   { path: 'release-dates', element: <ReleaseDates /> },
        //   { path: 'single-select', element: <SingleAttributes /> },
        //   { path: 'vat', element: <VAT /> },
        // ],
      },
      {
        path: 'dropship',
        element: <Outlet />,
        children: [
          // { path: 'vat', element: isAdmin(user) ? <VatCode /> : <Navigate to="/" /> },
          // { path: 'pricing', element: isAdmin(user) ? <Pricing /> : <Navigate to="/" /> },
          {
            path: 'wizards',
            element: <Outlet />,
            children: [
              { path: 'product', element: renderWithSiteCheck(<ProductWizard />) },
            ],
          },
        ],
      },
      {
        path: 'data-management',
        element: isAdminOrAppManager(user),
        children: [
          { path: 'attributes-definition', element: renderWithSiteCheck(<AttributeDefinitions />) },
          { path: 'brands', element: renderWithSiteCheck(<Brands />) },
          { path: 'brand-grouping', element: renderWithSiteCheck(<BrandGroupingContainer />) },
          { path: 'size-mapping', element: renderWithSiteCheck(<SizeMapping />) },
          { path: 'category', element: renderWithSiteCheck(<Category />) },
          { path: 'colour-matching', element: renderWithSiteCheck(<ColourMatching />) },

          {
            path: 'product-type',
            element: <Outlet />,
            children: [
              { index: true, element: renderWithSiteCheck(<ProductTypes />) },
              { path: 'attributes', element: renderWithSiteCheck(<ProductTypeAttributes />) },
            ],
          },
        ],
      },
      {
        path: 'settings',
        element: isAdminOrAppManager(user),
        children: [
          {
            path: 'indexes',
            element: !user?.userProfile?.user_permission === 'Admin' || !user?.userProfile?.user_permission === 'GroupAdmin'
              ? <Navigate to="/" /> : <Indexing />,
          },
          {
            path: 'user-management',
            element: user?.userProfile?.user_permission === 'Admin' || user?.userProfile?.user_permission === 'GroupAdmin'
              ? <UserManagement /> : <Navigate to="/" />,
          },
          { path: 'release-notes', element: <ReleaseNotes /> },
        ],
      },
      { path: 'image-checker', element: isAdminImageChecker(user) ? <ImageChecker /> : <Navigate to="/" /> },
      {
        path: 'styles',
        element: renderWithSiteCheck(<Styles />),
      },
      {
        path: 'margin-threshold',
        element: selectedSite?.SiteCode === 'JQ' ? <MarginThreshold /> : <Navigate to="/" />,
      },
      // {
      //   path: 'release-notes',
      //   element: <ReleaseNotes />,
      // },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  };
}

export default MainRoutes;
