// import Cookies from 'js-cookie';
import React from 'react';
import { Navigate } from 'react-router-dom';

import Deployment from '../pages/deployment/Deployment';
import Login from '../pages/login/Login';
// import { useSelector } from 'react-redux';
// import { Navigate } from 'react-router';
// import { clearPending } from '../axios/pendingRequests';
import LoginLocal from '../pages/login/LoginLocal';
// import Maintenance from '../pages/maintenance/Maintenance';

/**
 *
 * Handles the route to the login page.
 * If you try and move got to the login page whilst already being logged in
 * Then you'll be redirected to the dashboard.
 *
 * @returns {object}
 * @example
 * {
 *   path: '/login',
 *   element: <Login />,
 * }
 */
function LoginRoute() {
  const maintenanceMode = 'no';
  const [isDeploymentTime, setIsDeploymentTime] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(null);
  React.useEffect(() => {
    const deploymentStartTime = new Date('2025-03-25T12:00:00');
    const deploymentEndTime = new Date('2025-03-25T13:30:00');

    const checkDeploymentStatus = () => {
      if (deploymentStartTime && deploymentEndTime) {
        const currentTime = new Date();

        if (currentTime >= deploymentStartTime && currentTime <= deploymentEndTime) {
          setIsDeploymentTime(true);
          const remainingTime = Math.floor((deploymentEndTime - currentTime) / 1000);
          setTimeLeft(remainingTime);
        } else {
          setIsDeploymentTime(false);
          setTimeLeft(null);
        }
      }
    };
    checkDeploymentStatus();

    // update countdown evry second

    const interval = setInterval(() => {
      checkDeploymentStatus();
      if (isDeploymentTime && timeLeft > 0) {
        setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isDeploymentTime, timeLeft]);

  // const handleMount = () => {
  //   const token = Cookies.get('AccessToken');
  //   const user = useSelector((state) => state.user);

  //   const loggedIn = token?.length > 0 && user?.userProfile?.typ === 'PrivateApi';

  //   clearPending();

  //   return loggedIn ? <Navigate to="/" /> : <Login />;
  // };
  const currentURL = window.location.href.replace(/\/$/, '');
  const { port } = window.location;
  const isLocalURL = currentURL === `http://localhost:${port}/login-local`
  || currentURL === 'https://local.prism.jdplc.com.127.0.0.1.nip.io/login-local';
  let loginLocal;
  if (maintenanceMode.toLowerCase() === 'yes' && isDeploymentTime && isLocalURL) {
    loginLocal = <Deployment />;
  } else if (isLocalURL) {
    loginLocal = <LoginLocal />;
  } else {
    loginLocal = <Navigate to="/login" />;
  }

  return {
    path: '/',
    children: [
      {
        path: 'login',
        element: maintenanceMode.toLowerCase() === 'yes' && isDeploymentTime ? <Deployment /> : <Login />,
      },
      {
        path: 'login-local',
        element: loginLocal,
      },
    ],
  };
}

export default LoginRoute;
