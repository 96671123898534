import getRequest from '../../../../../getRequest';

/**
 * Get a list of the product types.
 *
 * @param {number} siteId,
 * @param {number} attributeDefId,
 * @param {number} languageId,
 * @returns Product count
 */
export default function getAttributeProductsCount(siteId, attributeDefId, languageId) {
  const endpoint = `/oc-product-bulkapi/v1/bulk-process/get-product-counts-for-site-attribute/${siteId}/${attributeDefId}/${languageId}`;
  return new Promise((resolve, reject) => {
    getRequest(endpoint, 'Get Product Count')
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
